import XLSX from "xlsx";
import { saveAs } from "file-saver";

export const exportExcel = async (transactions) => {
    try {
        let dataArray = [
            ["Date", "Order Number", "Item", "Quantity", "Unit", "Price"],
        ];
        for (let i = 0; i < transactions.length; i++) {
            let date = new Date(transactions[i].created_at);
            let order = transactions[i].orderNumber;
            for (let j = 0; j < transactions[i].items.length; j++) {
                let row = [
                    String(date),
                    String(order),
                    String(transactions[i].items[j].title),
                    String(transactions[i].items[j].count),
                    String(transactions[i].items[j].unit),
                    String(transactions[i].items[j].price),
                ];
                dataArray.push(row);
            }
        }
        console.log(dataArray);
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Local Guru Report",
            CreatedDate: new Date(),
        };
        wb.SheetNames.push("Report");
        let wsData = dataArray;
        let ws = XLSX.utils.aoa_to_sheet(wsData);
        wb.Sheets["Report"] = ws;

        let wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        saveAs(
            new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
            "LocalGuruReport.xlsx"
        );
    } catch (error) {
        console.log("Error during exportExcel", error);
    }
};

function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}
