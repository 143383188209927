import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchTransactions,
  changeStatus,
  fetchTransactionsAdmin,
  copyAddresses,
} from "../../actions/TransactionActions";
import {
  Page,
  Card,
  ResourceList,
  TextStyle,
  Pagination,
  Avatar,
  Stack,
  Thumbnail,
  EmptyState,
  Button,
  Popover,
  ActionList,
  Badge,
  Autocomplete,
  Icon,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import Moment from "react-moment";
import styled from "styled-components";
import emptyOrders from "../../assets/empty-orders.svg";
import { withRouter } from "react-router-dom";
import AdminAllSellers from "./AdminAllSellers";
import { Switch } from "antd";

const PaginationFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionsWrapper = styled.div`
  display: flex;
  width: 15%;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 1%;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5%;
  width: 45%;
`;

class OrdersAdmin extends Component {
  state = {
    page: 1,
    isLoaded: false,
    modalOpen: false,
    modalType: "",
    selectedItems: [],
    selectedObjects: [],
    searchValue: "",
    activeSort: false,
    sort: "",
    activeFilter: false,
    filter: "",
    activeView: false,
    view: "Seller",
    options: [],
    deselectedOptions: [],
    selectedOptions: [],
    inputValue: "",
    selected: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.transactions !== prevProps.transactions) {
      this.setState({ isLoaded: true });
    }
  }

  handleSearchChange = (searchValue) => {
    this.setState({ searchValue });
  };

  handleSelectionChange = (selected) => {
    const unfoundItems = selected;
    const foundItems = [];
    const selectedItems = [];

    for (let transaction of this.props.transactions) {
      for (let index = 0; index < unfoundItems.length; index++) {
        const item = unfoundItems[index];
        if (transaction.id === item) {
          foundItems.push(transaction);
          selectedItems.push(item);
          unfoundItems.splice(index, 1);
          break;
        }
      }
    }

    this.setState({
      selectedItems,
      selectedObjects: foundItems,
    });
  };

  toggleActive = (actionList) => (event) => {
    this.setState({ [actionList]: !this.state[actionList] });
  };

  onPrev = () => {
    const { page } = this.state;
    const limit = 50;
    const firstProduct = Object.keys(this.props.transactions)[0];
    const offset = this.props.transactions[firstProduct].created_at;
    this.props.fetchProducts({ endBefore: offset, limit: limit }).then(() => {
      this.setState({ page: page - 1 });
      if (page === 2) {
        this.props.history.push(`/transactions`);
      } else {
        this.props.history.push(`/transactions/page/${this.state.page}`);
      }
    });
  };

  onNext = () => {
    const { page } = this.state;
    const limit = 50;
    const lastProduct = Object.keys(this.props.transactions)[49];
    const offset = this.props.transactions[lastProduct].created_at;
    this.props
      .fetchTransactions({ startAfter: offset, limit: limit })
      .then(() => {
        this.setState({ page: page + 1 });
        this.props.history.push(`/transactions/page/${this.state.page}`);
      });
  };

  handleModalToggle = (type) => {
    this.setState({ modalOpen: !this.state.modalOpen, modalType: type });
  };

  orderTotal = (item) => {
    let total = 0;

    Object.keys(item.items).map((i) => {
      const { price, count, producer } = item.items[i];

      if (producer === this.props.user.uid) {
        return (total = total + price * count);
      } else {
        return false;
      }
    });

    return total;
  };

  promotedBulkActions = [
    {
      content: "Set to On the Way",
      onAction: () =>
        this.props.changeStatus(
          this.state.selectedItems,
          "on the way",
          this.state.selectedObjects
        ),
    },
    {
      content: "Copy Delivery Addresses",
      onAction: () => copyAddresses(this.state.selectedObjects),
    },
  ];

  bulkActions = [
    {
      content: "Set to Processing",
      onAction: () =>
        this.props.changeStatus(this.state.selectedItems, "processing"),
    },
    {
      content: "Set to Delivered",
      onAction: () =>
        this.props.changeStatus(this.state.selectedItems, "delivered"),
    },
    {
      content: "Cancel Order",
      onAction: () =>
        this.props.changeStatus(this.state.selectedItems, "cancelled"),
    },
  ];

  listItems = (item) => {
    let list = [];

    Object.keys(item.items).map((i, index) => {
      const { count, title } = item.items[i];

      return (list[index] = `${title} x${count}`);
    });

    return list.join(", ");
  };

  getBadge = (status) => {
    switch (status) {
      case "processing":
        return <Badge status="info">Processing</Badge>;
      case "delivered":
        return <Badge status="success">Delivered</Badge>;
      case "on the way":
        return <Badge status="attention">On The Way</Badge>;
      case "cancelled":
        return <Badge status="critical">Cancelled</Badge>;
      default:
        break;
    }
  };

  transformTransactions = (transactions) => {
    // create empty object
    let orders = {};
    let arr = [];

    switch (this.state.filter) {
      case "all":
        arr = [...transactions];
        break;
      case "processing":
        for (let transaction of transactions) {
          if (transaction.status === "processing") {
            arr.push(transaction);
          }
        }
        break;
      case "delivered":
        for (let transaction of transactions) {
          if (transaction.status === "delivered") {
            arr.push(transaction);
          }
        }
        break;
      case "on the way":
        for (let transaction of transactions) {
          if (transaction.status === "on the way") {
            arr.push(transaction);
          }
        }
        break;
      case "cancelled":
        for (let transaction of transactions) {
          if (transaction.status === "cancelled") {
            arr.push(transaction);
          }
        }
        break;
      default:
        arr = [...transactions];
        break;
    }

    switch (this.state.sort) {
      case "alphabetically":
        arr.sort(function (a, b) {
          let textA = a.user.displayName.toUpperCase();
          let textB = b.user.displayName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        break;
      case "date":
        arr.sort(function (a, b) {
          let num1 = a.created_at;
          let num2 = b.created_at;
          return num1 < num2 ? 1 : num1 > num2 ? -1 : 0;
        });
        break;
      case "money":
        arr.sort(function (a, b) {
          var textA = a.total;
          var textB = b.total;
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        });
        break;
      default:
        arr.sort(function (a, b) {
          var textA = a.user.displayName.toUpperCase();
          var textB = b.user.displayName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        break;
    }

    // Map through transactions array
    arr.forEach((transaction) => {
      // Get User ID
      const { created_at } = transaction;

      orders = { ...orders, [created_at]: transaction };
    });
    return Object.values(orders);
  };

  changeView = () => {
    const view = this.state.view === "seller" ? "Buyer" : "Seller";
    this.setState({ ...this.state, view });
  };

  transformSearchOptions = () => {
    const trxs = this.props.transactions;
    const options = {};
    for (let trx of trxs) {
      for (let producerInfo of trx.items) {
        if (!options[producerInfo.producer]) {
          options[producerInfo.producer] = producerInfo.producerName;
        }
      }
    }
    const tmpOpts = [];
    for (let [key, val] of Object.entries(options)) {
      tmpOpts.push({ value: key, label: val });
    }
    this.setState({
      deselectedOptions: tmpOpts,
      options: tmpOpts,
      isLoaded: true,
    });
  };

  updateSelection = (selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOption = this.state.options.find((option) => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    this.setState({ inputValue: selectedValue[0] });
    this.setState({ selectedOptions: selectedValue });
  };

  updateText = (value) => {
    this.setState({ inputValue: value });

    if (value === "") {
      this.setState({ options: this.state.deselectedOptions });
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = this.state.deselectedOptions.filter((option) => {
      return option.label.match(filterRegex);
    });
    this.setState({ options: resultOptions });
  };

  renderItem = (item) => {
    const { id, title, created_at, user, status } = item;

    return (
      <ResourceList.Item
        id={id}
        media={
          <Avatar
            customer
            source={user.photoURL ? user.photoURL : null}
            name={user.displayName}
          />
        }
        // url={`/order/view/${user.uid}`}
        accessibilityLabel={`View details for ${title}`}
      >
        <h3>
          <TextStyle variation="strong">{user.displayName}</TextStyle>
        </h3>
        <p>
          <TextStyle variation="subdued">
            <Moment format="MMMM Do, YYYY" unix>
              {created_at / 1000}
            </Moment>
          </TextStyle>
        </p>
        <br />
        <p>
          <strong>Status: </strong>
          {this.getBadge(status)}
        </p>
        <p>
          <strong>Total:</strong> ${item.total.toFixed(2)}
        </p>
        <p>
          <strong>Items:</strong>{" "}
          <TextStyle variation="subdued">{this.listItems(item)}</TextStyle>
        </p>
        <p>
          <strong>Order: </strong>
          <TextStyle variation="subdued">
            {item.orderNumber?.toUpperCase()}
          </TextStyle>
        </p>
        <p>
          <strong>Shipping Address: </strong>
          <TextStyle variation="subdued">
            {item.shippingAddress?.street},&nbsp;
            {item.shippingAddress?.city},&nbsp;
            {item.shippingAddress?.state},&nbsp;
            {item.shippingAddress?.zip}
          </TextStyle>
        </p>
        <br />
        <Card title="Items" sectioned>
          <ResourceList
            items={Object.values(item.items)}
            renderItem={(item) => {
              const { count, price, title, image, unit, producerName } = item;

              const media = <Thumbnail source={image} alt={title} />;

              return (
                <ResourceList.Item media={media}>
                  <Stack distribution="fillEvenly" spacing="extraLoose">
                    <h3>
                      <TextStyle variation="strong">{title}</TextStyle>
                      <br />
                      {producerName}
                    </h3>
                    <p>
                      {count} x {unit}
                    </p>
                    <p>${(count * price).toFixed(2)}</p>
                  </Stack>
                </ResourceList.Item>
              );
            }}
          />
        </Card>
      </ResourceList.Item>
    );
  };
  componentDidMount() {
    console.log("FIRED HERE");
    this.props.fetchTransactions();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.transactions !== this.props.transactions) {
      this.transformSearchOptions();
    }
  }

  render() {
    const resourceName = {
      singular: "order",
      plural: "orders",
    };

    const sortActivator = (
      <Button onClick={this.toggleActive("activeSort")} disclosure>
        Sort
      </Button>
    );

    const filterActivator = (
      <Button onClick={this.toggleActive("activeFilter")} disclosure>
        Filter
      </Button>
    );

    const viewActivator = (
      <Button onClick={() => this.setState({ activeView: true })} disclosure>
        {this.state.view}
      </Button>
    );

    const textField = (
      <Autocomplete.TextField
        onChange={this.updateText}
        label="Search Producers"
        value={this.state.inputValue}
        prefix={<Icon source={SearchMinor} color="inkLighter" />}
        placeholder="Search"
      />
    );

    return (
      <Page
        title={
          Object.keys(this.props.transactions).length > 0 ? "Orders" : null
        }
      >
        {this.state.isLoaded &&
        Object.keys(this.props.transactions).length > 0 ? (
          <>
            <br />
            <Card title="Orders Panel" sectioned>
              <Wrapper>
                <InputWrapper>
                  <Autocomplete
                    options={this.state.options}
                    selected={this.state.selectedOptions}
                    onSelect={this.updateSelection}
                    textField={textField}
                  />
                </InputWrapper>
                <OptionsWrapper>
                  <Popover
                    active={this.state.activeFilter}
                    activator={filterActivator}
                    onClose={this.toggleActive("activeFilter")}
                  >
                    <ActionList
                      items={[
                        {
                          content: "All",
                          onAction: () =>
                            this.setState({
                              filter: "all",
                              activeFilter: false,
                            }),
                        },
                        {
                          content: "Processing",
                          onAction: () =>
                            this.setState({
                              filter: "processing",
                              activeFilter: false,
                            }),
                        },
                        {
                          content: "On The Way",
                          onAction: () =>
                            this.setState({
                              filter: "on the way",
                              activeFilter: false,
                            }),
                        },
                        {
                          content: "Delivered",
                          onAction: () =>
                            this.setState({
                              filter: "delivered",
                              activeFilter: false,
                            }),
                        },
                        {
                          content: "Cancelled",
                          onAction: () =>
                            this.setState({
                              filter: "cancelled",
                              activeFilter: false,
                            }),
                        },
                      ]}
                    />
                  </Popover>
                  <div style={{ margin: "0 0 0 6px" }}>
                    <Popover
                      active={this.state.activeSort}
                      activator={sortActivator}
                      onClose={this.toggleActive("activeSort")}
                    >
                      <ActionList
                        items={[
                          {
                            content: "Alphabetically",
                            onAction: () =>
                              this.setState({
                                sort: "alphabetically",
                                activeSort: false,
                              }),
                          },
                          {
                            content: "By Date",
                            onAction: () =>
                              this.setState({
                                sort: "date",
                                activeSort: false,
                              }),
                          },
                          {
                            content: "By Money",
                            onAction: () =>
                              this.setState({
                                sort: "money",
                                activeSort: false,
                              }),
                          },
                        ]}
                      />
                    </Popover>
                  </div>
                  <div style={{ margin: "0 0 0 6px" }}>
                    <Popover
                      active={this.state.activeView}
                      activator={viewActivator}
                      onClose={this.changeView}
                    >
                      <ActionList
                        items={[
                          {
                            content: "Seller",
                            onAction: () =>
                              this.setState({
                                view: "Seller",
                                activeView: false,
                              }),
                          },
                          {
                            content: "Buyer",
                            onAction: () =>
                              this.setState({
                                view: "Buyer",
                                activeView: false,
                              }),
                          },
                        ]}
                      />
                    </Popover>
                  </div>
                </OptionsWrapper>
              </Wrapper>
              {this.state.view === "Seller" ? (
                <div>
                  <AdminAllSellers inputValue={this.state.inputValue} />
                </div>
              ) : (
                <ResourceList
                  resourceName={resourceName}
                  items={this.transformTransactions(this.props.transactions)}
                  renderItem={this.renderItem}
                  selectedItems={this.state.selectedItems}
                  onSelectionChange={this.handleSelectionChange}
                  promotedBulkActions={this.promotedBulkActions}
                  bulkActions={this.bulkActions}
                />
              )}

              {Object.keys(this.props.transactions).length > 49 && (
                <PaginationFooter>
                  <Pagination
                    hasPrevious={this.state.page > 1}
                    previousKeys={[74]}
                    previousTooltip="j"
                    onPrevious={this.onPrev}
                    hasNext
                    nextKeys={[75]}
                    nextTooltip="k"
                    onNext={this.onNext}
                  />
                </PaginationFooter>
              )}
            </Card>
          </>
        ) : (
          <EmptyState
            image={emptyOrders}
            heading="Manage Orders"
            action={{ content: "Edit products", url: "/products" }}
            secondaryAction={{ content: "Learn more", url: "/help" }}
          >
            Looks like there aren't any orders yet. Check back soon!
          </EmptyState>
        )}
      </Page>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => ({
      transactions: state.transactions,
      user: state.user,
    }),
    { fetchTransactions, fetchTransactionsAdmin, changeStatus }
  )(OrdersAdmin)
);
