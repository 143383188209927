import React, { useState } from "react";
import {
  Card,
  Icon,
  TextField,
  FormLayout,
  Select,
  AppProvider,
} from "@shopify/polaris";
import { CardElement } from "react-stripe-elements";

export default function BankAccountForm({
  user,
  account,
  setAccount,
  routing,
  setRouting,
}) {
  const [selectedAccountType, setSelectedAccountType] = useState("debit card");

  const options = ["debit card", "bank account"];

  return (
    <AppProvider>
      <Card.Section>
        <div style={{ width: "80%", display: "flex", alignItems: "center" }}>
          <h4
            style={{
              fontSize: "1.6rem",
              fontWeight: "bold",
            }}
          >
            Payout Method
          </h4>
          <div style={{ width: "60%", padding: "2%" }}>
            <Select
              value={selectedAccountType}
              options={options}
              onChange={(value) => {
                setSelectedAccountType(value);
              }}
            ></Select>
          </div>
        </div>
      </Card.Section>
      <Card.Section>
        {selectedAccountType === "debit card" ? (
          <div
            style={{
              width: "65%",
              border: "1px solid lightgray",
              padding: "1%",
            }}
          >
            <CardElement
              style={{
                base: {
                  fontSize: "16px",
                  background: "lightgray",
                },
              }}
            />
          </div>
        ) : (
          <FormLayout>
            <TextField
              type="password"
              placeholder="Routing Number"
              onChange={(value) => {
                setRouting(value);
              }}
              value={routing}
            />
            <TextField
              type="password"
              placeholder="Account Number"
              value={account}
              onChange={(value) => setAccount(value)}
            />
          </FormLayout>
        )}
      </Card.Section>
    </AppProvider>
  );
}
