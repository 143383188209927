import React, { useState, useEffect } from "react";
import { Card, Icon, Spinner } from "@shopify/polaris";
import { injectStripe } from "react-stripe-elements";
import {
    CreditCardMajorMonotone,
    BankMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import { connect } from "react-redux";
import {
    getStripeCardToken,
    getStripeBankToken,
    cloudFunctionUrl,
} from "../../actions/UserActions";
import Axios from "axios";
import BankAccountForm from "../../components/BankAccountForm";
import useStripeInfo from "../../hooks/useStripeInfo";
import useBankForm from "../../hooks/useBankForm";

const UserCard = styled.div`
    display: flex;

    span {
        margin: inherit;
        margin-right: 1rem;
    }
`;

const PayoutCard = ({
    user,
    stripe,
    getStripeBankToken,
    getStripeCardToken,
}) => {
    const [formOpen, setFormOpen] = useState(user.payoutCard ? false : true);
    const [loading, setLoading] = useState(false);
    const { account, setAccount, routing, setRouting } = useBankForm();
    const { stripeUser, error } = useStripeInfo(user);

    useEffect(() => {
        if (user.payoutCard) {
            setFormOpen(false);
        }
    }, [user.payoutCard, user.stripe_account]);

    const handleSubmit = async () => {
        setLoading(true);
        if (account !== "") {
            getStripeBankToken(
                stripe,
                routing,
                account,
                user.displayName,
                setLoading,
                setFormOpen,
                user
            );
        } else {
            getStripeCardToken(
                stripe,
                user.displayName,
                setLoading,
                setFormOpen,
                user
            );
        }
    };

    const getStripeConnectUrl = () => {
        Axios.post(`${cloudFunctionUrl}/getStripeConnectUrl`, {
            stripe_account: user.stripe_account,
        })
            .then((res) => (window.location.href = res.data.url))
            .catch((err) => console.log("ACCOUNT LINK ERR", err));
    };

    return (
        <Card
            title={
                stripeUser && stripeUser.requirements.currently_due.length < 2
                    ? "Debit Card for Payout"
                    : "Stripe Verification Required"
            }
            sectioned
            secondaryFooterActions={
                formOpen &&
                user.payoutCard && [
                    { content: "Cancel", onAction: () => setFormOpen(false) },
                ]
            }
            primaryFooterAction={
                stripeUser && stripeUser.requirements.currently_due.length < 2
                    ? formOpen
                        ? {
                              content: "Add Method",

                              onAction: () => handleSubmit(),
                              loading: loading,
                          }
                        : {
                              content: "Change Method",
                              onAction: () => setFormOpen(true),
                              loading: loading,
                          }
                    : {
                          content: "Verify now",
                          onAction: () => getStripeConnectUrl(),
                      }
            }>
            {user.payoutCard ? (
                formOpen ? (
                    <BankAccountForm
                        user={user}
                        account={account}
                        setAccount={setAccount}
                        routing={routing}
                        setRouting={setRouting}
                    />
                ) : (
                    <UserCard>
                        <Icon
                            source={
                                user.payoutCard.bank_name
                                    ? BankMajorMonotone
                                    : CreditCardMajorMonotone
                            }
                        />
                        {user.payoutCard.bank_name
                            ? `${user.payoutCard.bank_name} ${user.payoutCard.last4}`
                            : `${user.payoutCard.brand} ${user.payoutCard.last4}`}
                    </UserCard>
                )
            ) : (
                <BankAccountForm
                    user={user}
                    account={account}
                    setAccount={setAccount}
                    routing={routing}
                    setRouting={setRouting}
                />
            )}
        </Card>
    );
};

export default injectStripe(
    connect(null, { getStripeBankToken, getStripeCardToken })(PayoutCard)
);
