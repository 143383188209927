import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navigation } from "@shopify/polaris";
import {
  HomeMajorMonotone,
  ProductsMajorMonotone,
  OrdersMajorMonotone,
  AnalyticsMajorMonotone,
  CustomersMajorMonotone,
  ViewMajorMonotone,
  SettingsMajorMonotone,
  InventoryMajorMonotone,
  // DisputeMinor,
  QuestionMarkMajorMonotone,
  LogOutMinor,
} from "@shopify/polaris-icons";
import { useSelector } from "react-redux";

const { Section } = Navigation;

const Nav = ({ logout }) => {
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const history = useHistory();

  let businessDetails = false;

  if (user.businessDetails) {
    const { businessName, fullName, street, city, zip } = user.businessDetails;

    if (
      businessName !== "" &&
      fullName !== "" &&
      street !== "" &&
      zip !== "" &&
      city !== ""
    ) {
      businessDetails = true;
    }
  }

  return (
    <Navigation location={pathname}>
      <Section
        items={[
          {
            url: "/home",
            label: "Home",
            icon: HomeMajorMonotone,
          },
          {
            url: "/orders",
            label: "Orders",
            icon: OrdersMajorMonotone,
          },
          // {
          //   url: "/disputes",
          //   label: "Disputes",
          //   badge: "2",
          //   icon: DisputeMinor
          // },
          {
            url: "/followers",
            label: "Followers",
            icon: CustomersMajorMonotone,
          },
          {
            url: "/products",
            label: "Products",
            icon: ProductsMajorMonotone,
            subNavigationItems: businessDetails
              ? [
                  {
                    url: "/products",
                    label: "All Products",
                  },
                  {
                    url: "/products/add",
                    label: "Add New Product",
                  },
                ]
              : [
                  {
                    url: "/products",
                    label: "All Products",
                  },
                ],
          },
          {
            url: "/analytics",
            label: "Analytics",
            icon: AnalyticsMajorMonotone,
          },
          {
            url: "/open-house",
            label: "Open House",
            icon: InventoryMajorMonotone,
          },
        ]}
      />
      <Section
        title="Settings"
        separator
        items={[
          {
            url: "/account",
            label: "Account",
            icon: SettingsMajorMonotone,
          },
          {
            label: "View your profile",
            icon: ViewMajorMonotone,
            onClick: () => {
              window.open(`https://localguru.co/producer/${user.uid}`);
            },
          },
          {
            url: "/help",
            label: "FAQ",
            icon: QuestionMarkMajorMonotone,
          },
          {
            label: "Sign Out",
            icon: LogOutMinor,
            onClick: () => {
              logout();
              history.push("/");
            },
          },
        ]}
      />
    </Navigation>
  );
};

export default Nav;
