import { FETCH_TRANSACTIONS } from "../actions/TransactionActions";

export default function (state = { loaded: false }, action) {
  switch (action.type) {
    case FETCH_TRANSACTIONS:
      console.log("PAYLOAD", action.payload);
      return action.payload;
    default:
      return state;
  }
}
