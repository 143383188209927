import React from "react";
import {
  Card,
  Thumbnail,
  Stack,
  TextContainer,
  TextStyle
} from "@shopify/polaris";

const { Section } = Card;

const getOverview = (orders, user) => {
  let items = {};

  orders.map(order => {
    return Object.keys(order.items).filter(item => {
      const { producer } = order.items[
        item
      ];
      if (producer === user && order.status === "processing") {
        return true;
      } else {
        return false;
      }
    }).map(item => {
      const { count, price, title, image, unit, uid } = order.items[
        item
      ];
      if (items[uid]) {
        return (items[uid] = {
          ...items[uid],
          count: count + items[uid].count,
        });
      } else {
        return (items[uid] = {
          count: count,
          price: price,
          title: title,
          image: image,
          unit: unit,
        });
      }
    })
  });

  return Object.values(items);
};

const OrderOverview = ({ orders, user }) => (
  <Card sectioned title="Processing Overview">
    {getOverview(orders, user).map((order, index) => (
      <Section key={index}>
        <Stack alignment="center">
          <Thumbnail size="large" source={order.image} alt={order.title} />
          <TextContainer>
            {order.title}
            <br />
            <TextStyle variation="subdued">
              Amount: {order.count} {order.unit}s
            </TextStyle>
            <br />
            <TextStyle variation="subdued">
              Total: ${(order.count * order.price).toFixed(2)}
            </TextStyle>
          </TextContainer>
        </Stack>
      </Section>
    ))}
  </Card>
);

export default OrderOverview;
