import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import {
  Page,
  Layout,
  Card,
  Button,
  Heading
} from "@shopify/polaris";
import FAQModal from './FAQModal';
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { TOGGLE_TOAST } from "../../actions/UIActions";

const { Section } = Layout;

const ButtonBlock = styled.div`
  margin: 1rem 0 0 70vw;
`

const Title = styled.div`
  margin: 2rem 70vw 2rem 0;
  h2 {
      font-size: 150%;
  }
`;

const EditPageFAQ = (props) => {

    const dispatch = useDispatch();
    const { history } = props;

    const [loaded, setLoaded] = useState(false);
    const [values, setValues] = useState({});
    const [active, setActive] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await db.collection("pages").doc("faq").get();
          setValues(res.data())
          setLoaded(true);
        } catch (e) {
          console.log(e);
        }
      };

      if (!loaded) {
        fetchData();
      }
    }, [loaded]);

    const handleChange = useCallback((section) => {
        setActive(!active);
        setActiveSection(section);
        setQuestion('');
        setAnswer('');
    }, [active]);

    const handleQuestion = useCallback((value) => setQuestion(value), []);

    const handleAnswer = useCallback((value) => setAnswer(value), []);

    const addQuestion = useCallback((newQ, section) => {
        let newArray = values[section];
        newArray.push(newQ);

        setValues((prevValues) => ({
            ...prevValues,
          [section]: newArray,
        }));
      }, [values]
    )

    const handleSave = useCallback(() => {
        let newQ = {
            question: question,
            answer: answer
        }
        addQuestion(newQ, activeSection);
        setQuestion('');
        setAnswer('');
        setActive(!active);
    }, [active, activeSection, question, answer, addQuestion]);

    const deleteQuestion = useCallback((index, section) => {
        let newArray = values[section];
        newArray.splice(index, 1);

        setValues((prevValues) => ({
            ...prevValues,
          [section]: newArray,
        }));
      }, [values]
    )

    const saveValues = useCallback(async () => {
        try {
            await db.collection("pages").doc("faq").set(values);
            dispatch({ type: TOGGLE_TOAST, payload: "FAQ updated" });
        } catch (e) {
            console.log(e)
        }
      },
      [values, dispatch]
    );

    const viewFAQ = () => {
      history.push("/faq-preview");
    };


    if (values.delivery) {
        return (
          <>
          <Page
            title="Edit FAQ"
            separator
            primaryAction={{
              content: "Save",
              onAction: saveValues,
            }}
            secondaryActions={[
              {
                content: "View FAQ Page",
                onAction: viewFAQ,
              },
            ]}
          >
            <Layout>
                <Title>
                    <Heading>Delivery</Heading>
                </Title>
              {values.delivery.map((item, index) => (
                <Section key={index}>
                  <Card sectioned>
                    <strong>{item.question}</strong>
                    <p>{item.answer}</p><br></br>
                    <Button plain destructive onClick={() => deleteQuestion(index, 'delivery')}>Delete</Button>
                  </Card>
                </Section>
              ))}
                <ButtonBlock>
                <Button onClick={() => handleChange('delivery')}>Add question</Button>
                </ButtonBlock>
                <Title>
                    <Heading>Billing</Heading>
                </Title>
              {values.billing.map((item, index) => (
                <Section key={index}>
                  <Card sectioned>
                    <strong>{item.question}</strong>
                    <p>{item.answer}</p><br></br>
                    <Button plain destructive onClick={() => deleteQuestion(index, 'billing')}>Delete</Button>
                  </Card>
                </Section>
              ))}
                <ButtonBlock>
                    <Button onClick={() => handleChange('billing')}>Add question</Button>
                </ButtonBlock>
                <Title>
                    <Heading>Produce</Heading>
                </Title>
              {values.produce.map((item, index) => (
                <Section key={index}>
                  <Card sectioned>
                    <strong>{item.question}</strong>
                    <p>{item.answer}</p><br></br>
                    <Button plain destructive onClick={() => deleteQuestion(index, 'produce')}>Delete</Button>
                  </Card>
                </Section>
              ))}
                <ButtonBlock>
                <Button onClick={() => handleChange('produce')}>Add question</Button>
                </ButtonBlock>
            </Layout>
          </Page>
          <FAQModal 
          active={active} 
          handleChange={handleChange} 
          handleSave={handleSave}
          activeSection={activeSection}
          question={question}
          handleQuestion={handleQuestion}
          answer={answer}
          handleAnswer={handleAnswer} 
          />
          </>
        );
    } else {
        return null
    }
};

export default EditPageFAQ;
