import React, { Component } from "react";
import { connect } from "react-redux";
import { Page, DisplayText, TextStyle, CalloutCard } from "@shopify/polaris";
import styled from "styled-components";

const Wrapper = styled.div`
  .Polaris-DisplayText {
    text-transform: capitalize;
  }
`;

class Home extends Component {
  render() {
    console.log("WE ARE IN HOME", this.props);
    return (
      <Wrapper>
        <Page title={`Welcome, ${this.props.user.displayName}`}>
          <TextStyle variation="subdued">
            <DisplayText>
              Here's what's happening with your products today
            </DisplayText>
          </TextStyle>
          <br />
          {this.props.user.bio && this.props.user.photoURL ? (
            <>
              {this.props.user.businessDetails ? (
                <CalloutCard
                  title="Look at your orders"
                  primaryAction={{
                    content: "View Orders",
                    url: "/orders",
                  }}
                >
                  <p>See what is being bought</p>
                </CalloutCard>
              ) : (
                <CalloutCard
                  title="Set Up Your Business Information"
                  primaryAction={{
                    content: "Edit profile",
                    url: "/account",
                  }}
                >
                  <p>The next step in completing your profile</p>
                </CalloutCard>
              )}
            </>
          ) : (
            <CalloutCard
              title="Set up your profile"
              illustration="https://apps.shopify.com/assets/v2/category-images/marketing-addb6d46025cb3e65cac1fbf8a56c248739dc20d0606d8550b217d11574f44fe.svg"
              primaryAction={{
                content: "Edit profile",
                url: "/account",
              }}
            >
              <p>Tell us a bit about yourself</p>
            </CalloutCard>
          )}
          {/* 
            TODO: This can show up at all times but if we can find a way to
            make it conditional so it isnt super annoying we should. 
            Likely only showing it if theres no products at all
          */}
          <CalloutCard
            title="Add new products to your shop"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
              content: "Add new product",
              url: "/products/add",
            }}
          >
            <p>Add a new product for sale on your shop.</p>
          </CalloutCard>
        </Page>
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
  }),
  {}
)(Home);
