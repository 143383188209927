import React, { useCallback, useState } from 'react';
import {Button, TextField } from '@shopify/polaris';
import { TOGGLE_TOAST } from "../actions/UIActions";
import { useDispatch } from "react-redux";
import { validateProducer } from '../actions/UserActions';

export default function ProducerValidation( props ) {
    const [textFieldValue, setTextFieldValue] = useState('');
  
    const handleTextFieldChange = useCallback(
      (value) => setTextFieldValue(value),
      [],
    );

    const dispatch = useDispatch();

    const handleSubmit = () => {
        validateProducer(textFieldValue)
        .then(() => {
        dispatch({ type: TOGGLE_TOAST, payload: 'Producer added' });
            setTextFieldValue('');
        }).catch((error) => {
            dispatch({ type: TOGGLE_TOAST, payload: 'Error, try again' });
        })
    }
  
    return (
      <TextField
        label="Validate New Producer"
        type="email"
        value={textFieldValue}
        onChange={handleTextFieldChange}
        connectedRight={<Button onClick={handleSubmit}>Submit</Button>}
        helpText="Add new producers here so they can register successfully"
      />
    );
  }