import React from "react";
import {
    Modal,
    FormLayout,
    TextField
  } from "@shopify/polaris";

function FAQModal(props) {
  
    return (
      <div>
        <Modal
          open={props.active}
          onClose={props.handleChange}
          title={'Add question to ' + props.activeSection}
          primaryAction={{
            content: 'Add',
            onAction: props.handleSave,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: props.handleChange,
            },
          ]}
        >
          <Modal.Section>
          <FormLayout>
            <TextField value={props.question} label="Question" onChange={props.handleQuestion} />
            <TextField value={props.answer} label="Answer" onChange={props.handleAnswer} />
          </FormLayout>
          </Modal.Section>
        </Modal>
      </div>
    );
  }

  export default FAQModal;