import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Navigation } from "@shopify/polaris";
import PropTypes from "prop-types";
import {
    HomeMajorMonotone,
    ProductsMajorMonotone,
    OrdersMajorMonotone,
    AnalyticsMajorMonotone,
    ThemeEditMajorMonotone,
    EditMajorMonotone,
    LogOutMinor,
    CapitalMajorMonotone,
} from "@shopify/polaris-icons";

const Section = Navigation.Section;

class Nav extends Component {
    componentDidMount() {
        console.log(`NavAdmin component mounted successfully`);
    }
    componentDidUpdate(prevProps, prevState) {
        console.log(`NavAdmin component updated successfully`);
    }
    render() {
        return (
            <Navigation location={this.props.location.pathname}>
                <Section
                    title='Admin'
                    items={[
                        {
                            url: "/home",
                            label: "Home",
                            icon: HomeMajorMonotone,
                        },
                        {
                            url: "/orders",
                            label: "Orders",
                            icon: OrdersMajorMonotone,
                        },
                        {
                            url: "/products",
                            label: "Products",
                            icon: ProductsMajorMonotone,
                            subNavigationItems: [
                                {
                                    url: "/products",
                                    label: "All Products",
                                },
                                {
                                    url: "/products/add",
                                    label: "Add New Product",
                                },
                                {
                                    url: "/products/categories",
                                    label: "Categories",
                                },
                                {
                                    url: "/products/categories/add",
                                    label: "Add New Category",
                                },
                            ],
                        },
                        {
                            url: "/producers",
                            label: "Producers",
                            icon: CapitalMajorMonotone,
                        },
                        {
                            url: "/analytics",
                            label: "Analytics",
                            icon: AnalyticsMajorMonotone,
                        },
                    ]}
                />
                <Section
                    title='Content'
                    separator
                    items={[
                        {
                            label: "Home Page",
                            url: "/pages/edit/home",
                            icon: ThemeEditMajorMonotone,
                        },
                        {
                            label: "Legal Page",
                            url: "/pages/edit/legal",
                            icon: EditMajorMonotone,
                            subNavigationItems: [
                                {
                                    url: "/pages/edit/legal",
                                    label: "Policies",
                                },
                                {
                                    url: "/pages/edit/faq",
                                    label: "FAQ",
                                },
                            ],
                        },
                    ]}
                />
                <Section
                    title='Settings'
                    separator
                    items={[
                        {
                            label: "Sign Out",
                            icon: LogOutMinor,
                            onClick: () => {
                                this.props.logout();
                                this.props.history.push("/");
                            },
                        },
                    ]}
                />
            </Navigation>
        );
    }
}

Nav.propTypes = {
    location: PropTypes.object.isRequired,
};

export default withRouter(Nav);
