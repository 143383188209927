import React, { useState, useCallback } from "react";
import { Page, Card, FormLayout, TextField } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import { TOGGLE_TOAST } from "../../actions/UIActions";
import { useDispatch } from "react-redux";

const AddCategory = () => {
  const [values, setValues] = useState({
    title: "",
    fee: "0",
    description: '',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { title, fee, description } = values;

  const handleTextFieldChange = (value, id) => {
    setValues({
      ...values,
      [id]: value
    })
  }

  const handleSubmit = useCallback(async () => {
    let id = "";
    try {
      const res = await db.collection("categories").add(values);
      id = res.id;
      db.collection("categories").doc(id).set(
        {
          id: id,
        },
        { merge: true }
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: TOGGLE_TOAST, payload: "Category created" });
      history.push(`/products/categories/${id}`);
    }
  }, [dispatch, history, values]);

  return (
    <Page
      title="Add Category"
      breadcrumbs={[{ content: "Categories", url: "/products/categories" }]}
      primaryAction={{
        content: "Save",
        onAction: () => handleSubmit(),
      }}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Title"
              value={title}
              id="title"
              onChange={(value, id) => handleTextFieldChange(value, id)}
            />
            <TextField
              label="Fee"
              type="number"
              value={`${fee}`}
              id="fee"
              onChange={(value, id) => handleTextFieldChange(value, id)}
              prefix="$"
            />
          </FormLayout.Group>

          <TextField
            label="Description"
            id="description"
            value={description}
            onChange={(value, id) => handleTextFieldChange(value, id)}
          />
        </FormLayout>
      </Card>
    </Page>
  );
};

export default AddCategory;
