import { auth, db, functions, storage } from "../firebase";
import Axios from "axios";
import { CLEAR_ANALYTICS } from "../reducers/AnalyticsReducer";
import devComment from "../utils/devComment";
import loadCloudFunctionUrl from "../utils/cloudFunctionUrlLoader";
export const LOGIN_USER = "login_user";
export const LOGOUT_USER = "logout_user";
export const TOGGLE_TOAST = "toggle_toast";

export const cloudFunctionUrl = loadCloudFunctionUrl();

export function loginUser() {
    return (dispatch) => {
        return auth.onAuthStateChanged(async (user) => {
            if (user) {
                const producer = await db
                    .collection("producers")
                    .doc(user.uid)
                    .get();
                if (producer.exists) {
                    Axios.post(`${cloudFunctionUrl}/getStripeInfo`, user).then(
                        async (res) => {
                            const stripeDetails = await db
                                .collection("producers")
                                .doc(user.uid)
                                .collection("stripe_details")
                                .doc(producer.data().stripe_account)
                                .get();
                            return dispatch({
                                type: LOGIN_USER,
                                payload: {
                                    ...producer.data(),
                                    ...stripeDetails.data(),
                                },
                            });
                        }
                    );
                } else {
                    return db
                        .collection("producers")
                        .doc(user.uid)
                        .set({
                            displayName: user.displayName,
                            email: user.email,
                            uid: user.uid,
                            followers: [],
                        })
                        .then(() => {
                            window.location.reload(true);
                        });
                }
            } else {
                return dispatch({
                    type: LOGOUT_USER,
                });
            }
        });
    };
}

export function getProducer() {
    return (dispatch) => {
        return auth.onAuthStateChanged(async (user) => {
            if (user && user.uid) {
                const userData = await db
                    .collection("producers")
                    .doc(user.uid)
                    .get();
                Axios.post(`${cloudFunctionUrl}/getStripeInfo`, user).then(
                    async (res) => {
                        console.log("PRODUCER RES STRIPE", res);
                        const stripeDetails = await db
                            .collection("producers")
                            .doc(user.uid)
                            .collection("stripe_details")
                            .doc(res.data.id)
                            .get();

                        console.log("STRIPEDATA", stripeDetails.data());
                        return dispatch({
                            type: LOGIN_USER,
                            payload: {
                                ...userData.data(),
                                ...stripeDetails.data(),
                            },
                        });
                    }
                );
            } else {
                return dispatch({
                    type: LOGOUT_USER,
                });
            }
        });
    };
}

export function logoutUser() {
    return (dispatch) => {
        console.log("logging out");
        auth.signOut();
        // .then(() => {
        //   return dispatch({
        //     type: LOGOUT_USER
        //   })
        // })
        // .catch(err => {
        //   console.error(err)
        // })
        dispatch({ type: LOGOUT_USER });
        dispatch({ type: CLEAR_ANALYTICS });
    };
}

export function addPayoutCard(user, token, setLoading) {
    return (dispatch) => {
        Axios.post(`${cloudFunctionUrl}/addPayoutCard`, {
            user,
            token: token.id,
        })
            .then(async (res) => {
                console.log(res);

                const updatedSeller = await db
                    .collection("producers")
                    .doc(user.uid)
                    .get();
                const stripeDetails = await db
                    .collection("producers")
                    .doc(user.uid)
                    .collection("stripe_details")
                    .doc(user.stripe_account)
                    .get();
                console.log(
                    "SELLER",
                    updatedSeller,
                    "STRIPE DETAILS",
                    stripeDetails
                );
                dispatch({
                    type: LOGIN_USER,
                    payload: {
                        ...updatedSeller.data(),
                        ...stripeDetails.data(),
                    },
                });
                dispatch({ type: TOGGLE_TOAST, payload: res.data.message });
                setLoading(false);
                // setFormOpen(false);
            })
            .catch((err) => {
                dispatch({
                    type: TOGGLE_TOAST,
                    payload: err.response.data.message,
                });
                setLoading(false);
            });
        // console.log(token);
        // console.log(user);
        // return functions
        //   .httpsCallable("addPayoutCard")({ user: user, token: token.id })
        //   .then((res) => {
        //     console.log(res);
        //   });
    };
}

export function addBankAccount(user, token) {
    return (dispatch) => {
        console.log(token);
        return functions
            .httpsCallable("addBankAccount")({ user: user, token: token.id })
            .then((res) => {
                console.log(res);
            });
    };
}

export function editProfile(user, bio, photo, organicPractices) {
    return (dispatch) => {
        if (photo !== "") {
            return storage
                .ref()
                .child(`producers/${photo.name}`)
                .put(photo)
                .then((snapshot) => {
                    return snapshot.ref.getDownloadURL();
                })
                .then((photoURL) => {
                    return db.collection("producers").doc(user).set(
                        {
                            photoURL: photoURL,
                            bio: bio,
                            organicPractices: organicPractices,
                        },
                        { merge: true }
                    );
                })
                .catch((err) => console.log(err));
        } else {
            return db
                .collection("producers")
                .doc(user)
                .set(
                    {
                        bio: bio,
                        organicPractices: organicPractices,
                    },
                    { merge: true }
                )
                .catch((err) => console.log(err));
        }
    };
}

export const createAccount = async (account, profile, type) => {
    try {
        const response = await auth.createUserWithEmailAndPassword(
            account.email,
            account.password
        );
        profile.uid = response.user.uid;
        db.collection(type).doc(response.user.uid).set(profile);
        devComment("Account created successfully");
    } catch (error) {
        console.log("Error during createAccount", error);
    }
};

export const validateProducer = async (email) => {
    console.log("VALIDATION EMAIL", email);
    try {
        let ref = db.collection("validatedProducers");
        await ref.add({ email }).then((docRef) => {
            db.collection("validatedProducers").doc(docRef.id).set({
                email,
                uid: docRef.id,
            });
        });
    } catch (error) {
        console.log("Error during validateProducer", error);
    }
};

export const verifyProducer = async (email) => {
    try {
        let result = false;
        await db
            .collection("validatedProducers")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    const item = doc.data();
                    if (item.email === email) {
                        result = true;
                    }
                });
            });
        console.log(result);
        return result;
    } catch (error) {
        console.log("Error during verifyProducer", error);
    }
};

export const getStripeConnectUrl = (stripe_account, connectStatus) => {
    return Axios.post(`${cloudFunctionUrl}/getStripeConnectUrl`, {
        stripe_account,
        connectStatus,
    })
        .then((res) => {
            console.log("CONNECT URL", res);
            return res.data.url;
        })
        .catch((err) => {
            console.log("CONNECT ERROR", err);
            return err;
        });
};

export const getStripeCardToken = (
    stripe,
    name,
    setLoading,
    setFormOpen,
    user
) => {
    return async (dispatch) => {
        try {
            const { error, token } = await stripe.createToken({
                type: "card",
                name: name,
                currency: "usd",
            });
            if (token) {
                Axios.post(`${cloudFunctionUrl}/addPayoutCard`, {
                    user,
                    token: token.id,
                })
                    .then(async (res) => {
                        console.log(res);

                        const updatedSeller = await db
                            .collection("producers")
                            .doc(user.uid)
                            .get();
                        const stripeInfo = await db
                            .collection("producers")
                            .doc(user.uid)
                            .collection("stripe_details")
                            .doc(updatedSeller.data().stripe_account)
                            .get();
                        console.log(
                            "SELLER",
                            updatedSeller.data(),
                            stripeInfo.data()
                        );
                        dispatch({
                            type: LOGIN_USER,
                            payload: {
                                ...updatedSeller.data(),
                                ...stripeInfo.data(),
                            },
                        });
                        dispatch({
                            type: TOGGLE_TOAST,
                            payload: res.data.message,
                        });
                        setLoading(false);
                        setFormOpen(false);
                    })
                    .catch((err) => {
                        dispatch({
                            type: TOGGLE_TOAST,
                            payload: err.response.data.message,
                        });
                        setLoading(false);
                    });
            }
            return token;
        } catch (err) {
            console.log("TOKEN ERROR", err);
        }
    };
};

export const getStripeBankToken = (
    stripe,
    routing,
    account,
    name,
    setLoading,
    setFormOpen,
    user
) => {
    return async (dispatch) => {
        try {
            const { error, token } = await stripe.createToken("bank_account", {
                country: "US",
                currency: "usd",
                routing_number: routing,
                account_number: account,
                account_holder_name: name,
                account_holder_type: "individual",
            });

            if (token) {
                return Axios.post(`${cloudFunctionUrl}/addPayoutCard`, {
                    user,
                    token: token.id,
                })
                    .then(async (res) => {
                        console.log(res);

                        const updatedSeller = await db
                            .collection("producers")
                            .doc(user.uid)
                            .get();
                        const stripeInfo = await db
                            .collection("producers")
                            .doc(user.uid)
                            .collection("stripe_details")
                            .doc(updatedSeller.data().stripe_account)
                            .get();
                        dispatch({
                            type: LOGIN_USER,
                            payload: {
                                ...updatedSeller.data(),
                                ...stripeInfo.data(),
                            },
                        });
                        dispatch({
                            type: TOGGLE_TOAST,
                            payload: res.data.message,
                        });
                        setLoading(false);
                        setFormOpen(false);
                    })
                    .catch((err) => {
                        console.log("TOKEN POST ERR", err);
                        dispatch({
                            type: TOGGLE_TOAST,
                            payload: err.response.data.message,
                        });
                        setLoading(false);
                    });
            }
            return token;
        } catch (err) {
            console.log("TOKEN ERROR", err);
        }
    };
};

export const testThunk = () => {
    return (dispatch) => {
        console.log("THIS IS DISPATCH", dispatch);
        return;
    };
};
