import { combineReducers } from "redux";
import ProductReducer from "./ProductReducer";
import UIReducer from "./UIReducer";
import UserReducer from "./UserReducer";
import TransactionReducer from "./TransactionReducer";
import AnalyticsReducer from "./AnalyticsReducer";

const rootReducer = combineReducers({
  ui: UIReducer,
  user: UserReducer,
  products: ProductReducer,
  transactions: TransactionReducer,
  analytics: AnalyticsReducer,
});

export default rootReducer;
