import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Page, Card, FormLayout, TextField } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { TOGGLE_TOAST } from "../../actions/UIActions";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hide = styled.button`
  background: linear-gradient(180deg, #ffea49, #ffc107);
  color: black;
  border: 0.1rem solid;
  border-color: #e6ba34;
  padding: 0.7rem 1.6rem;
  border-radius: 3px;
`;

const Delete = styled.button`
  background: linear-gradient(180deg, #e6391a, #d53417);
  color: white;
  border: 0.1rem solid;
  border-color: #b02b13;
  padding: 0.7rem 1.6rem;
  border-radius: 3px;
`;

const Show = styled.button`
  background: linear-gradient(180deg, #6371c7, #5563c1);
  color: white;
  border: 0.1rem solid;
  border-color: #3f4eae;
  padding: 0.7rem 1.6rem;
  border-radius: 3px;
`;

const EditCategory = () => {
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState({});
  const [touched, setTouched] = useState(false);
  const [original, setOriginal] = useState({})
  const history = useHistory();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const doc = await db
        .collection(`categories`)
        .doc(id)
        .get();

      let data = doc.data();
      data.productCount = 0;
      await db
        .collection("products")
        .where("category", "==", data.title.toLowerCase())
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach(async (doc) => {
              data.productCount += 1;
            });
          }
        });
      setValues(data);
      setOriginal(data);
      setLoaded(true);
    };

    if (!loaded) {
      fetchData();
    }
  }, [id, loaded]);

  const { title, fee, description, hidden, productCount } = values;

  const handleTextFieldChange = useCallback(
    (value, id) => {
      setValues(prevValues => ({
        ...values,
        [id]: value
      }));
      setTouched(true);
    },
    [values]
  );

  const handleSubmit = useCallback(async () => {
    try {
      await db.collection(`categories`).doc(id).update(values);
      if (values.title !== original.title) {
        await db
          .collection("products")
          .where("category", "==", original.title.toLowerCase())
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              snapshot.forEach(async (doc) => {
                let data = doc.data();

                await db.collection("products").doc(data.id).update({
                  category: values.title.toLowerCase(),
                });
              });
            }
          });
      }
      setOriginal(values);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: TOGGLE_TOAST, payload: `Category saved` });
    }
  }, [dispatch, id, values, original]);

  const handleHide = useCallback(
    async (id, hidden) => {
      if (!hidden) {
        let data = values;
        data.hidden = true;
        db.collection("categories").doc(id).update(data);
        setValues(data);
      } else {
        let data = values
        data.hidden = false;
        db.collection("categories").doc(id).update(data);
        setValues(data)
      }
    },
    [values]
  );

  const handleDelete = useCallback(async (id, hidden) => {
    await db.collection("categories").doc(id).delete()

    history.push(`/products/categories/`);
  }, [history]);

  return (
    <Page
      title="Edit Category"
      breadcrumbs={[{ content: "Categories", url: "/products/categories" }]}
      primaryAction={{
        content: "Save",
        disabled: !touched,
        onAction: () => handleSubmit(),
      }}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Title"
              value={title}
              id="title"
              onChange={(value, id) => handleTextFieldChange(value, id)}
            />
            <TextField
              label="Fee"
              type="number"
              value={`${fee}`}
              id="fee"
              onChange={(value, id) => handleTextFieldChange(value, id)}
              prefix="$"
            />
          </FormLayout.Group>

          <TextField
            label="Description"
            id="description"
            value={description}
            onChange={(value, id) => handleTextFieldChange(value, id)}
          />
          <Buttons>
            {hidden ? (
              <Show onClick={() => handleHide(id, hidden)}>Show</Show>
            ) : (
              <Hide onClick={() => handleHide(id, hidden)}>Hide</Hide>
            )}
            {productCount <= 0 && <Delete onClick={() => handleDelete(id, title)}>Delete</Delete>}
          </Buttons>
        </FormLayout>
      </Card>
    </Page>
  );
};

export default EditCategory;
