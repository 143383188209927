import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addBankAccount,
  addPayoutCard,
  editProfile,
  getStripeConnectUrl,
} from "../../actions/UserActions";
import { toggleToast } from "../../actions/UIActions";
import AvatarUpload from "../../components/AvatarUpload";
import { injectStripe } from "react-stripe-elements";
import {
  Thumbnail,
  Page,
  Layout,
  Card,
  TextField,
  FormLayout,
  Button,
  Checkbox,
} from "@shopify/polaris";
import {
  ViewMajorMonotone,
  CircleAlertMajorMonotone,
  CircleTickMajorMonotone,
} from "@shopify/polaris-icons";
import Password from "./Password";
import Email from "./Email";
import Business from "./Business";
import PayoutCard from "./PayoutCard";
import { message } from "antd";

const { AnnotatedSection } = Layout;

class Settings extends Component {
  state = {
    routing: "",
    account: "",
    formOpen: false,
    bio: "",
    photo: "",
    dropzoneOpen: false,
    organicPractices: false,
    loading: false,
  };

  componentDidMount() {
    if (this.props.user.bio) {
      this.setState({
        bio: this.props.user.bio,
      });
    }
    if (this.props.user.organicPractices) {
      this.setState({
        organicPractices: this.props.user.organicPractices,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      if (this.props.user.bio) {
        this.setState({
          bio: this.props.user.bio,
        });
      }
      if (this.props.user.organicPractices) {
        this.setState({
          organicPractices: this.props.user.organicPractices,
        });
      }
    }
  }

  handleAddBank = () => {
    const { user, stripe } = this.props;
    const { routing, account } = this.state;

    stripe
      .createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: routing,
        account_number: account,
        account_holder_name: user.displayName,
        account_holder_type: "individual",
      })
      .then((res) => {
        this.props.toggleToast("Bank account added");
      })
      .catch((err) => {
        this.props.toggleToast(err.message);
      });
  };

  handleAddCard = (e) => {
    e.preventDefault();

    const { user, stripe } = this.props;

    stripe
      .createToken({
        type: "card",
        name: this.props.user.displayName,
        currency: "usd",
      })
      .then(({ error, token }) => {
        if (error) {
          throw new Error(error.message);
        }
        this.props.addPayoutCard(user, token);
      })
      .then((res) => {
        this.props.toggleToast("Card added");
      })
      .catch((err) => {
        this.props.toggleToast(err.message);
      });
  };

  handleChange = (value, id) => {
    this.setState({ [id]: value });
  };

  handleCheckbox = () => {
    this.setState({ organicPractices: !this.state.organicPractices });
  };

  handleOpenForm = () => {
    this.setState({ formOpen: !this.state.formOpen });
  };

  handleEditProfile = () => {
    const user = this.props.user.uid;
    const { bio, photo, organicPractices } = this.state;
    this.props
      .editProfile(user, bio, photo, organicPractices)
      .then((res) => {
        this.props.toggleToast("Profile updated");
      })
      .catch((err) => {
        this.props.toggleToast(err.message);
      });
  };

  handleUpload = (file) => {
    console.log(file);
    this.setState({ photo: file });
  };

  verifyStripeDetails = async (e) => {
    e.preventDefault();
    this.setState({ ...this.state, loading: true });
    try {
      const url = await getStripeConnectUrl(
        this.props.user.stripe_account,
        this.props.user.connectStatus
      );
      window.location.href = url;
    } catch (err) {
      console.log("ACCOUNT CONNECT ERROR ===>", err);
      message.error(err.mesage);
      this.setState({ ...this.state, loading: false });
    }
  };

  render() {
    const stripeDetails = this.props.user.stripe_details.requirements
      .currently_due;
    return (
      <div>
        <Page
          title="Account Settings"
          secondaryActions={[
            {
              content: "View your profile",
              icon: ViewMajorMonotone,
              external: true,
              url: `https://local-guru-aeac9.firebaseapp.com/producer/${this.props.user.uid}`,
            },
          ]}
        >
          <Layout>
            <AnnotatedSection
              title="Account Verification"
              description="Local Guru uses indsutry leading payment provider Stripe to
              process payments. As a requirement, Local Guru seller's are
              required to verify their indentity/business details with
              Stripe. "
            >
              <Card
                title="Verification"
                sectioned
                actions={[
                  {
                    icon:
                      stripeDetails.length > 0
                        ? CircleAlertMajorMonotone
                        : CircleTickMajorMonotone,
                  },
                ]}
                primaryFooterAction={
                  stripeDetails.length >= 1
                    ? {
                        content: "Verify now",
                        onClick: (e) => this.verifyStripeDetails(e),
                        loading: this.state.loading,
                      }
                    : stripeDetails.length === 0
                    ? {
                        content: "Update information",
                        onClick: (e) => this.verifyStripeDetails(e),
                      }
                    : null
                }
              >
                {stripeDetails.length > 0 ? (
                  <div>
                    {stripeDetails.length === 1 &&
                    stripeDetails[0] === "external_account" ? (
                      <div>
                        <p style={{ paddingBottom: "2%" }}>
                          One last step, to be able to receive payments from
                          Local Guru, you must provide your banking information.
                        </p>
                        <p style={{ fontWeight: "bold" }}>
                          To complete verification, add payout method below.
                        </p>
                      </div>
                    ) : (
                      <p>
                        Before being able to receive payments from Local Guru,
                        you must verify your account.
                      </p>
                    )}
                  </div>
                ) : (
                  <div>
                    <p>You're all set. Account verified.</p>
                    <p style={{ marginTop: "2%" }}>
                      If you need to update your legal information in the
                      future, you can update it here.
                    </p>
                  </div>
                )}
              </Card>
            </AnnotatedSection>
            <AnnotatedSection
              title="Bio"
              description="Your account and contact information"
            >
              <Card
                title="Profile"
                sectioned
                primaryFooterAction={{
                  content: "Update profile",
                  onAction: () => this.handleEditProfile(),
                }}
              >
                <FormLayout>
                  <TextField
                    id="bio"
                    value={this.state.bio}
                    label="Bio"
                    onChange={(event) => this.handleChange(event, "bio")}
                  />
                  {this.state.dropzoneOpen ? (
                    <div>
                      <p>
                        <AvatarUpload onChange={this.handleUpload} />
                      </p>
                      <br />
                      <p>
                        <Button
                          plain
                          onClick={() => this.setState({ dropzoneOpen: false })}
                        >
                          Cancel
                        </Button>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        <Thumbnail
                          size="large"
                          source={this.props.user.photoURL}
                        />
                      </p>
                      <br />
                      <p>
                        <Button
                          plain
                          onClick={() => this.setState({ dropzoneOpen: true })}
                        >
                          Change image
                        </Button>
                      </p>
                    </div>
                  )}
                  <Checkbox
                    label="Please confirm your farm complies with organic practices. This must be checked before adding any products"
                    checked={this.state.organicPractices}
                    onChange={this.handleCheckbox}
                  />
                </FormLayout>
              </Card>
            </AnnotatedSection>
            <AnnotatedSection
              title="Business details"
              description="This address will appear on your invoices and be available to customers for open houses"
            >
              <Business user={this.props.user} />
            </AnnotatedSection>
            <AnnotatedSection title="Email" description="Change your email">
              <Email />
            </AnnotatedSection>
            <AnnotatedSection
              title="Password"
              description="Reset your password"
            >
              <Password />
            </AnnotatedSection>

            <AnnotatedSection
              id="payment"
              title="Payment"
              description="This information will be used for your payouts. However, Local Guru doesn't keep any of your account
              details in our database, all the sensitive data is stored safely in Stripe servers"
            >
              <Card title="Payment">
                <PayoutCard user={this.props.user} />
              </Card>
            </AnnotatedSection>
          </Layout>
        </Page>
      </div>
    );
  }
}

export default injectStripe(
  connect(
    (state, ownProps) => ({
      user: state.user,
    }),
    { addBankAccount, addPayoutCard, toggleToast, editProfile }
  )(Settings)
);
