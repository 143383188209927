import React, { Component } from 'react';
import { Page, Card } from "@shopify/polaris";
import styled from "styled-components";
import { db } from "../firebase";

const TextBlock = styled.div`
  margin-top: 2.5rem;
`;

const Policy = styled.div`
  margin: 2rem 1rem;
`;

const InnerTextBlock = styled.div`
  margin: 0.75rem 0;
`;

class Terms extends Component {
  state = {
    terms: {},
  };

  getTerms = async () => {
    try {
      const res = await db.collection("pages").doc("legal").get();
      this.setState({
        terms: res.data(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    this.getTerms();
  }

  editTerms = () => {
    this.props.history.push("/pages/edit/legal");
  }

  render() {
    return (
      <Page
        title="Terms of Service"
        primaryAction={{
          content: "Edit",
          onAction: this.editTerms,
        }}
      >
        <Card sectioned>
          {this.state.terms.policies &&
            this.state.terms.policies.map((policy, index) => (
              <Policy key={index}>
                <h4>{policy.title}</h4>
                {policy.blocks.map((block, id) => (
                  <>
                    {block.type === "text" && (
                      <TextBlock>
                        {block.text.split("\n").map((text) => (
                          <InnerTextBlock>{text}</InnerTextBlock>
                        ))}
                      </TextBlock>
                    )}
                    {block.type === "list" && (
                      <ol>
                        {block.list.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ol>
                    )}
                    {block.type === "bullet" && (
                      <ul>
                        {block.list.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    )}
                  </>
                ))}
              </Policy>
            ))}
        </Card>
      </Page>
    );
  }
}

export default Terms;
