import React, { useState, useEffect } from "react";
import Axios from "axios";
import { cloudFunctionUrl } from "../actions/UserActions";

export default function useStripeInfo(user) {
    const [stripeUser, setStripeUser] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        Axios.post(`${cloudFunctionUrl}/getStripeInfo`, user)
            .then((res) => {
                setStripeUser(res.data);
            })
            .catch((err) => setError(err));
    }, [user]);

    return {
        stripeUser,
        error,
    };
}
