import React, { useEffect, useState } from "react";
import { Card, ResourceList, Thumbnail, AppProvider } from "@shopify/polaris";
import { useSelector } from "react-redux";
export default function AdminAllSellers({ inputValue }) {
  const transactions = useSelector((state) => state.transactions);
  const [producerTrxs, setProducerTrxs] = useState({});

  const transformTransactions = (trxs) => {
    const producerItems = {};
    for (let trx of trxs) {
      for (let item of trx.items) {
        if (producerItems[item.producerName]) {
          if (producerItems[item.producerName][item.title]) {
            if (producerItems[item.producerName][item.title][item.unit]) {
              producerItems[item.producerName][item.title][item.unit] +=
                item.count;
            } else {
              producerItems[item.producerName][item.title] = {
                [item.unit]: item.count,
                img: item.image,
              };
            }
          } else {
            producerItems[item.producerName][item.title] = {
              [item.unit]: item.count,
              img: item.image,
            };
          }
        } else {
          producerItems[item.producerName] = {
            [item.title]: { [item.unit]: item.count, img: item.image },
          };
        }
      }
    }
    setProducerTrxs(producerItems);
  };

  const transformSearchTrxs = (trxs, val) => {
    const producerItems = {};
    for (let trx of trxs) {
      for (let item of trx.items) {
        const filterRegex = new RegExp(val, "i");
        const prodName = item.producerName;
        const title = item.title;
        const image = item.image;
        const unit = item.unit;
        if (prodName && prodName.match(filterRegex)) {
          if (producerItems[prodName]) {
            if (producerItems[prodName][title]) {
              if (producerItems[prodName][title][unit]) {
                producerItems[prodName][title][unit] += item.count;
              } else {
                producerItems[prodName][title] = {
                  [unit]: item.count,
                  img: image,
                };
              }
            } else {
              producerItems[prodName][title] = {
                [item.unit]: item.count,
                img: image,
              };
            }
          } else {
            producerItems[prodName] = {
              [title]: { [unit]: item.count, img: image },
            };
          }
        }
      }
    }
    setProducerTrxs(producerItems);
  };

  const renderItem = (item) => {
    const media = <Thumbnail source={item[1].img} alt={item[0]} />;
    return (
      <ResourceList.Item media={media}>
        <div>
          <h3 style={{ fontWeight: "bold", marginBottom: "0.2%" }}>
            {item[0]}
          </h3>
          <div>
            <p>
              Units
              {Object.entries(item[1])
                .filter((el) => el[0] !== "img")
                .map((unit, idx) => {
                  return (
                    <p key={idx}>
                      {unit[1]} {unit[0]}
                    </p>
                  );
                })}
            </p>
          </div>
        </div>
      </ResourceList.Item>
    );
  };
  useEffect(() => {
    !inputValue && transformTransactions(transactions);
  }, [transactions, inputValue]);

  useEffect(() => {
    if (inputValue && inputValue !== "") {
      transformSearchTrxs(transactions, inputValue);
    }
  }, [inputValue]);

  return (
    <AppProvider>
      {Object.entries(producerTrxs).map((producer, idx) => {
        return (
          <Card key={idx} title={producer[0]}>
            <ResourceList
              items={Object.entries(producer[1])}
              renderItem={(item) => renderItem(item)}
            ></ResourceList>
          </Card>
        );
      })}
    </AppProvider>
  );
}
