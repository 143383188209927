import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Page, Heading, Layout, Tabs, Spinner } from "@shopify/polaris";
import AnalyticBlock from "./AnalyticBlock";
import { useSelector, useDispatch } from "react-redux";
import ProductCard from "../../components/ProductCard";

import { setAnalytics, SET_ANALYTICS } from "../../actions/AnalyticsActions";
import Axios from "axios";
import { cloudFunctionUrl } from "../../actions/UserActions";

const { Section } = Layout;

const Analytics = () => {
    const [selected, setSelected] = useState(0);
    const [range, setRange] = useState("week");
    const [sortedItems, setSortedItems] = useState([]);
    const [weeklySorted, setWeeeklySorted] = useState([]);
    const [monthlySorted, setMonthlySorted] = useState([]);
    const [loading, setLoading] = useState(true);

    const user = useSelector((state) => state.user);
    const analytics = useSelector((state) => state.analytics);
    const dispatch = useDispatch();

    useEffect(() => {
        Axios.post(`${cloudFunctionUrl}/getAnalytics`, {
            admin: user.admin,
            user: user.uid,
        }).then((res) => {
            console.log("RES DATA", res);
            dispatch({ type: SET_ANALYTICS, payload: res.data });
        });
    }, []);

    useMemo(() => {
        setLoading(true);
        console.log("PRODUCT COUNT", analytics.productCount);
        const tempItems = Object.entries(analytics.productCount).sort(
            (a, b) => b[1] - a[1]
        );
        console.log("WEEKLY ANALYTICS", analytics.weekly);
        const tmpWeekly = Object.entries(analytics.weekly.tmpProductCount).sort(
            (a, b) => b[1] - a[1]
        );
        const tmpMonthly = Object.entries(
            analytics.monthly.tmpProductCount
        ).sort((a, b) => b[1] - a[1]);
        setSortedItems(tempItems);
        setWeeeklySorted(tmpWeekly);
        setMonthlySorted(tmpMonthly);
        setLoading(false);
    }, [analytics.loaded]);

    const tabs = [
        {
            id: "Week",
            content: "This Week",
            accessibilityLabel: "This Week",
            panelID: "week",
            onSelect: (index) => setSelected(index),
        },
        {
            id: "Month",
            content: "This Month",
            accessibilityLabel: "This Month",
            panelID: "month",
            onSelect: (index) => setSelected(index),
        },
        {
            id: "Year",
            content: "This Year",
            accessibilityLabel: "This Year",
            panelID: "year",
            onSelect: (index) => setSelected(index),
        },
    ];

    const handleTabChange = useCallback(
        (selectedTabIndex) => {
            setSelected(selectedTabIndex);
            setRange(tabs[selectedTabIndex].panelID);
        },

        [tabs]
    );

    return (
        <Page title='Analytics'>
            <Tabs
                tabs={tabs}
                selected={selected}
                onSelect={handleTabChange}></Tabs>
            <br />
            <br />
            <Heading>Overview</Heading>
            <br />
            {analytics.loaded ? (
                <>
                    <Layout>
                        <Section oneThird>
                            <AnalyticBlock
                                currency
                                data={
                                    selected === 0
                                        ? analytics.weekly.tmpSales.current
                                        : selected === 1
                                        ? analytics.monthly.tmpSales.current
                                        : analytics.sales
                                }
                                range={range}
                                change={
                                    analytics.sales.past
                                        ? Math.round(
                                              ((analytics.sales.current - 0) /
                                                  1) *
                                                  100
                                          )
                                        : null
                                }
                                title={`This ${tabs[selected].id}'s Sales`}
                            />
                        </Section>
                        <Section oneThird>
                            <AnalyticBlock
                                data={
                                    selected === 0
                                        ? analytics.weekly.tmpOrders.current
                                        : selected === 1
                                        ? analytics.monthly.tmpOrders.current
                                        : analytics.orders
                                }
                                range={range}
                                change={
                                    analytics.orders.past
                                        ? Math.round(
                                              ((analytics.orders.current - 0) /
                                                  1) *
                                                  100
                                          )
                                        : null
                                }
                                title={`This ${tabs[selected].id}'s Orders`}
                            />
                        </Section>
                        <Section oneThird>
                            <AnalyticBlock
                                data={
                                    selected === 0
                                        ? analytics.weekly.tmpViews.current
                                        : selected === 1
                                        ? analytics.monthly.tmpViews.current
                                        : analytics.views
                                }
                                range={range}
                                change={
                                    analytics.views.past
                                        ? Math.round(
                                              ((analytics.views - 0) / 1) * 100
                                          )
                                        : null
                                }
                                title={`This ${tabs[selected].id}'s Views`}
                            />
                        </Section>
                        <Section oneThird>
                            <AnalyticBlock
                                data={analytics.sales}
                                range={range}
                                change={
                                    analytics.followers
                                        ? Math.round(
                                              ((analytics.sales - 0) / 1) * 100
                                          )
                                        : null
                                }
                                title={`This ${tabs[selected].id}'s Followers`}
                            />
                        </Section>
                    </Layout>
                    <br />
                    <br />
                    <Heading>Most Popular Products</Heading>
                    <br />
                    <Layout>
                        {!loading && selected === 0
                            ? weeklySorted.map(([key, val]) => {
                                  return (
                                      <Section key={key} oneThird>
                                          <ProductCard
                                              title={
                                                  analytics.weekly
                                                      .tmpProductInfo[key].title
                                              }
                                              sales={val}
                                              image={
                                                  analytics.weekly
                                                      .tmpProductInfo[key].image
                                              }
                                          />
                                      </Section>
                                  );
                              })
                            : selected === 1
                            ? monthlySorted.map(([key, val]) => {
                                  return (
                                      <Section key={key} oneThird>
                                          <ProductCard
                                              title={
                                                  analytics.weekly
                                                      .tmpProductInfo[key].title
                                              }
                                              sales={val}
                                              image={
                                                  analytics.productInfo[key]
                                                      .image
                                              }
                                          />
                                      </Section>
                                  );
                              })
                            : sortedItems.map(([key, val]) => {
                                  return analytics.productInfo[key] ? (
                                      <Section key={key} oneThird>
                                          <ProductCard
                                              title={
                                                  analytics.productInfo[key]
                                                      .title
                                              }
                                              sales={val}
                                              image={
                                                  analytics.productInfo[key]
                                                      .image
                                              }
                                          />
                                      </Section>
                                  ) : null;
                              })}
                    </Layout>
                </>
            ) : (
                <div>
                    <Spinner
                        accessibilityLabel='Spinner analytics loading'
                        size='large'
                        color='inkLightest'
                    />
                </div>
            )}
        </Page>
    );
};

export default Analytics;
