import React, { Component } from "react";
import { auth, db } from "../firebase";
import { connect } from "react-redux";

class SignedIn extends Component {
    state = {};

    componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate");
        if (auth.currentUser) {
            let user = auth.currentUser;

            if (this.state.displayName !== user.displayName) {
                let { uid, displayName, email, photoURL } = user;
                // uid = "ChMBXh6PUXZstdbYWyCVOIppeQ03";
                // console.log("THE USER ==>", user.authenticated);
                this.setState({
                    displayName,
                });

                db.collection("producers")
                    .doc(uid)
                    .get()
                    .then(async (userDoc) => {
                        console.log("THIS IS USERDOC", userDoc.data());
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            // check if the producer has stripe_details (admins don't necessarily have a stripe account)
                            const hasStripeAccount = await db
                                .collection("producers")
                                .doc(uid)
                                .collection("stripe_details")
                                .get()
                                .then(
                                    (querySnapshot) =>
                                        querySnapshot.docs.length > 0
                                );

                            if (hasStripeAccount) {
                                const stripeDetails = await db
                                    .collection("producers")
                                    .doc(uid)
                                    .collection("stripe_details")
                                    .doc(userData.stripe_account)
                                    .get();
                                console.log(
                                    "STRIPE DETAILS",
                                    stripeDetails.data()
                                );
                                this.setState({
                                    loading: false,
                                });

                                return this.props.dispatch({
                                    type: "login_user",
                                    payload: {
                                        ...userDoc.data(),
                                        ...stripeDetails.data(),
                                    },
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                });

                                return this.props.dispatch({
                                    type: "login_user",
                                    payload: {
                                        ...userDoc.data(),
                                    },
                                });
                            }
                        } else {
                            let validated = false;

                            await db
                                .collection("validatedProducers")
                                .where("email", "==", email)
                                .get()
                                .then((snapshot) => {
                                    snapshot.forEach(async (doc) => {
                                        if (doc.data().email === email) {
                                            validated = true;
                                            await db
                                                .collection(
                                                    "validatedProducers"
                                                )
                                                .doc(doc.data().uid)
                                                .delete();
                                        }
                                    });
                                })
                                .then(async () => {
                                    if (validated) {
                                        await db
                                            .collection("producers")
                                            .doc(uid)
                                            .set({
                                                displayName: displayName,
                                                email: email,
                                                uid: uid,
                                                photoURL: photoURL,
                                                followers: [],
                                            })
                                            .then(() => {
                                                this.props.dispatch({
                                                    type: "login_user",
                                                    payload: {
                                                        displayName:
                                                            displayName,
                                                        email: email,
                                                        uid: uid,
                                                        photoURL: photoURL,
                                                        followers: [],
                                                    },
                                                });
                                            });
                                    } else {
                                        console.log("IT FAILED");
                                        window.location.assign("/");
                                    }
                                });
                        }
                    });
            }
        }
    }

    render() {
        return <></>;
    }
}

export default connect()(SignedIn);
