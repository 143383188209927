import { db } from "../firebase";
export const SET_ANALYTICS = "set_analytics";
export const SET_LOADING = "set_loading";

export const setAnalytics = async () => {
  let tmpOrders = { current: 0 };
  let tmpViews = { current: 0 };
  let tmpSales = { current: 0 };
  let tmpProductInfo = {};
  let tmpProductCount = {};
  const producerData = await db.collection("producers").get();

  const fetchData = async () => {
    producerData.docs.forEach(async (doc) => {
      const { uid } = doc.data();
      const prodAnalytics = await db
        .collection("producers")
        .doc(uid)
        .collection("analytics")
        .get();

      if (prodAnalytics.size > 0) {
        prodAnalytics.docs.forEach((doc) => {
          const { sales, products, views, orders } = doc.data();

          if (sales) {
            tmpSales.current += sales;
          }
          if (views) {
            tmpViews.current += views;
          }
          if (orders) {
            tmpOrders.current += orders;
          }
          if (products) {
            Object.entries(products).map(async (vals) => {
              if (!tmpProductInfo[vals[0]]) {
                const item = await db.collection("products").doc(vals[0]).get();
                if (item.exists) {
                  tmpProductInfo[vals[0]] = {
                    [vals[0]]: item.data(),
                  };
                }
              }
              if (tmpProductCount[vals[0]]) {
                tmpProductCount[vals[0]].current += vals[1];
              } else {
                tmpProductCount[vals[0]] = {
                  [vals[0]]: { current: vals[1] },
                };
              }
            });
          }
        });
      }
    });
    return {
      orders: tmpOrders,
      views: tmpViews,
      sales: tmpSales,
      productCount: tmpProductCount,
      productInfo: tmpProductInfo,
    };
  };
  return fetchData().then((data) => {
    console.log("FETCHED DATA", data);
    return data;
  });
};
