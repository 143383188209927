import React, { Component } from "react";
import styled from "styled-components";
import {
    Page,
    Avatar,
    Card,
    ResourceItem,
    ResourceList,
    TextStyle,
    EmptyState,
    Autocomplete,
    Icon,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import emptyFollowers from "../assets/empty-followers.svg";
import { db } from "../firebase";
import ProducerValidation from "../components/ProducerValidation";
import { connect } from "react-redux";
import { toggleToast } from "../actions/UIActions";

const Label = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 40%;
`;

const Delete = styled.button`
    background: linear-gradient(180deg, #e6391a, #d53417);
    color: white;
    border: 0.1rem solid;
    border-color: #b02b13;
`;

const Promote = styled.button`
    background: linear-gradient(180deg, #6371c7, #5563c1);
    color: white;
    border: 0.1rem solid;
    border-color: #3f4eae;
`;

const Demote = styled.button`
    background: linear-gradient(180deg, #ffea49, #ffc107);
    color: black;
    border: 0.1rem solid;
    border-color: #e6ba34;
`;

class Producers extends Component {
    state = {
        loading: true,
        producers: [],
        options: [],
        deselectedOptions: [],
        selectedOptions: [],
        inputValue: "",
        selected: null,
        searching: false,
    };

    getProducers = async () => {
        const res = await db
            .collection("producers")
            .orderBy("displayName")
            .get();
        const tempDoc = res.docs
            .filter((doc) => {
                if (doc.data().displayName) {
                    return true;
                } else {
                    return false;
                }
            })
            .map((doc) => ({ id: doc.id, ...doc.data() }));

        this.setState({
            loading: false,
            producers: tempDoc,
        });
    };

    componentDidMount() {
        console.log("Producers component mount");
        this.getProducers();
    }

    promoteUser = async (uid) => {
        await db.collection("producers").doc(uid).update({
            admin: true,
        });
        this.getProducers();
    };

    demoteUser = async (uid) => {
        await db.collection("producers").doc(uid).update({
            admin: false,
        });
        this.getProducers();
    };

    deleteUser = async (uid) => {
        const snapshot = await db
            .collection("products")
            .where(`producers`, "array-contains", { [`${uid}`]: true })
            .get();

        if (!snapshot.empty) {
            await snapshot.forEach(async (doc) => {
                console.log(doc.data());

                await db
                    .collection("products")
                    .doc(doc.data().id)
                    .get()
                    .then(async (newDoc) => {
                        console.log(newDoc.data());
                        if (newDoc.data()) {
                            await db
                                .collection("products")
                                .doc(newDoc.data().id)
                                .update({
                                    producers: newDoc
                                        .data()
                                        .producers.filter(
                                            (producer) =>
                                                Object.keys(producer)[0] !== uid
                                        ),
                                });
                        }
                    });

                await db
                    .collection("products")
                    .doc(doc.data().id)
                    .collection("producers")
                    .doc(uid)
                    .delete();
            });
        }

        await db.collection("producers").doc(uid).delete();
        this.getProducers();
    };

    showToast = (message) => {
        toggleToast(message);
    };

    updateSelection = (selected) => {
        const selectedValue = selected.map((selectedItem) => {
            const matchedOption = this.state.options.find((option) => {
                return option.value.match(selectedItem);
            });
            return matchedOption && matchedOption.label;
        });
        this.setState({ inputValue: selectedValue[0] });
        this.setState({ selectedOptions: selectedValue });
    };
    updateText = (value) => {
        this.setState({ inputValue: value });
        console.log("VALUE", value);

        if (value === "") {
            this.setState({
                options: this.state.deselectedOptions,
                searching: false,
                producers: this.props.producers,
            });

            return;
        }

        const filterRegex = new RegExp(value, "i");
        const resultOptions = this.state.deselectedOptions.filter((option) => {
            console.log("OPTION", option, filterRegex);
            return option.label.match(filterRegex);
        });
        this.setState({
            options: resultOptions,
            producers: resultOptions,
            searching: true,
        });
    };

    transformSearchOptions = () => {
        const producers = this.state.producers;
        const options = {};
        for (let producer of producers) {
            options[producer.id] = producer.displayName;
        }

        const tmpOpts = [];
        for (let [key, val] of Object.entries(options)) {
            tmpOpts.push({ value: key, label: val });
        }
        this.setState({
            deselectedOptions: tmpOpts,
            options: tmpOpts,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.producers !== prevState.producers &&
            !this.state.inputValue
        ) {
            console.log("STATE", this.state);
            this.transformSearchOptions();
        }
    }

    render() {
        const textField = (
            <Autocomplete.TextField
                onChange={this.updateText}
                label='Search Producers'
                value={this.state.inputValue}
                prefix={<Icon source={SearchMinor} color='inkLighter' />}
                placeholder='Search'
            />
        );
        return (
            <Page title={this.state.producers.length > 0 ? "Producers" : null}>
                {!this.state.loading && (
                    <>
                        {this.state.producers.length > 0 ? (
                            <>
                                <ProducerValidation
                                    toggleToast={this.showToast}
                                />
                                <br />
                                <Autocomplete
                                    options={this.state.options}
                                    selected={this.state.selectedOptions}
                                    onSelect={this.updateSelection}
                                    textField={textField}
                                />
                                <br />
                                <h2>{this.state.producers.length} producers</h2>
                                <br />
                                <Card>
                                    <ResourceList
                                        resourceName={{
                                            singular: "producer",
                                            plural: "producers",
                                        }}
                                        items={this.state.producers}
                                        persistActions={true}
                                        renderItem={(item) => {
                                            const {
                                                uid,
                                                displayName,
                                                photoURL,
                                                admin,
                                                email,
                                            } = item;
                                            return (
                                                <ResourceItem
                                                    id={uid}
                                                    media={
                                                        <Avatar
                                                            source={photoURL}
                                                            customer
                                                            size='medium'
                                                            name={displayName}
                                                        />
                                                    }>
                                                    <h3>
                                                        <TextStyle variation='strong'>
                                                            {displayName}
                                                        </TextStyle>
                                                    </h3>

                                                    <Label>
                                                        {admin ? (
                                                            <p>Admin</p>
                                                        ) : (
                                                            <p>Producer</p>
                                                        )}
                                                        <p>{email}</p>
                                                        <Buttons>
                                                            <Delete
                                                                onClick={() =>
                                                                    this.deleteUser(
                                                                        uid
                                                                    )
                                                                }>
                                                                Delete Producer
                                                            </Delete>
                                                            {admin ? (
                                                                <Demote
                                                                    onClick={() =>
                                                                        this.demoteUser(
                                                                            uid
                                                                        )
                                                                    }>
                                                                    Demote to
                                                                    Producer
                                                                </Demote>
                                                            ) : (
                                                                <Promote
                                                                    onClick={() =>
                                                                        this.promoteUser(
                                                                            uid
                                                                        )
                                                                    }>
                                                                    Promote to
                                                                    Admin
                                                                </Promote>
                                                            )}
                                                        </Buttons>
                                                    </Label>
                                                </ResourceItem>
                                            );
                                        }}
                                    />
                                </Card>
                            </>
                        ) : (
                            <EmptyState
                                heading='Producers'
                                image={emptyFollowers}
                                fullWidth={true}
                                // action={{ content: "Action here?" }}
                            >
                                Local Guru currently has no producers. Go out
                                and get us some local farmers!
                            </EmptyState>
                        )}
                    </>
                )}
            </Page>
        );
    }
}

export default connect()(Producers);
