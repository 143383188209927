import React, { useState, useCallback, useEffect } from "react";
import { Card, TextField, FormLayout, Badge, Select } from "@shopify/polaris";
import { db } from "../../firebase";
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";
const { Group } = FormLayout;

const Wrapper = styled.div``;

const StyledField = styled.div`
  span {
    margin-bottom: 0.5rem;
    display: block;
  }

  div {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border: 0.1rem solid var(--p-border, #c4cdd5);
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
      pointer-events: none;
    }

    .autocomplete {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.4rem;
      text-transform: none;
      letter-spacing: normal;
      position: relative;
      z-index: 20;
      display: block;
      flex: 1 1;
      width: 100%;
      min-width: 0;
      min-height: 3.6rem;
      margin: 0;
      padding: 0.5rem 1.2rem;
      background: none;
      border: 0.1rem solid transparent;
    }
  }
`;
const blockOptions = [
  { label: "Individual", value: "individual" },
  { label: "Company", value: "company" },
];

const Business = ({ user }) => {
  const defaultValues = {
    businessName: "",
    fullName: "",
    street: "",
    zip: "",
    phone: "",
    apt: "",
    city: "Minneapolis",
    businessType: "individual",
  };

  const [values, setValues] = useState(user.businessDetails || defaultValues);
  const [error, setError] = useState(false);
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [errorMessage, setMessage] = useState("");

  const handleTextField = useCallback((value, id) => {
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setTouched(true);
  }, []);

  const handleSubmit = useCallback(() => {
    db.collection("producers")
      .doc(user.uid)
      .update({
        businessDetails: { ...values, businessType },
      });
    setTouched(false);
  }, [user.uid, values, businessType]);

  // const deliveryCounties = ["Ramsey County", "Hennepin County"];

  const {
    businessName,
    fullName,
    street,
    zip,
    city,
    phone,
    businessType,
    state,
  } = values;

  useEffect(() => {
    console.log("VALUES", values);
  }, [values]);

  return (
    <Wrapper>
      <Card
        title="Business Details"
        sectioned
        primaryFooterAction={{
          content: "Update Business details",
          onAction: () => valid && handleSubmit(),
          disabled: !touched,
        }}
      >
        <FormLayout>
          <Select
            label={<strong>Select venture type</strong>}
            options={blockOptions}
            value={businessType}
            id="businessType"
            onChange={handleTextField}
          />
          <Group>
            {businessType === "company" && (
              <TextField
                label={"Legal business name"}
                id="businessName"
                value={businessName}
                onChange={handleTextField}
              />
            )}

            <TextField
              label="Your full name"
              id="fullName"
              value={fullName}
              onChange={handleTextField}
            />
            <TextField
              label="Phone number"
              id="phone"
              value={phone}
              maxLength={10}
              type="tel"
              onChange={handleTextField}
            />
          </Group>
          <StyledField>
            <span>Replace Address</span>
            <div>
              <Autocomplete
                className="autocomplete"
                placeholder="Enter your address"
                onPlaceSelected={(place) => {
                  const { address_components } = place;
                  try {
                    // const county = address_components[4].long_name;
                    //This if statement was changed to true on Damani's request to not have address restrictions
                    // if (true) {
                    setValues((prevValues) =>
                      address_components.length > 7
                        ? {
                            ...prevValues,
                            street: `${address_components[0].short_name} ${address_components[1].short_name}`,
                            city: address_components[3].short_name,
                            zip: address_components[7].short_name,
                            state: address_components[5].long_name,
                          }
                        : {
                            ...prevValues,
                            street: `${address_components[0].short_name} ${address_components[1].short_name}`,
                            city: address_components[2].short_name,
                            zip: address_components[6].short_name,
                            state: address_components[4].long_name,
                          }
                    );
                    setValid(true);
                    setError(false);
                    setTouched(true);
                    // } else {
                    //   setError(true);
                    //   setMessage(
                    //     "Your address must be in the Twin Cities area."
                    //   );
                    //   setValues((prevValues) => ({
                    //     ...prevValues,
                    //     street: "",
                    //     city: "",
                    //     apartment: "",
                    //     zip: "",
                    //   }));
                    // }
                  } catch {
                    setError(true);
                    setMessage("Please enter a valid Address.");
                    setValues((prevValues) => ({
                      ...prevValues,
                      street: "",
                      city: "",
                      apartment: "",
                      zip: "",
                    }));
                  }
                }}
                types={["address"]}
                componentRestrictions={{ country: "us" }}
              />
            </div>
          </StyledField>
          {error && <Badge status="warning">{errorMessage}</Badge>}
          <Group>
            <TextField
              label="Street"
              id="street"
              value={street}
              onChange={handleTextField}
              disabled
            />
            <TextField
              label="City"
              id="city"
              value={city}
              disabled
              onChange={handleTextField}
            />
          </Group>
          <Group>
            {/* <TextField
              label="Apartment"
              id="apt"
              value={apt}
              onChange={handleTextField}
              disabled
            /> */}
            <TextField
              label="ZIP code"
              id="zip"
              value={zip}
              onChange={handleTextField}
              disabled
            />
            <TextField
              label="State"
              id="state"
              value={state}
              onChange={handleTextField}
              disabled
            />
          </Group>
        </FormLayout>
      </Card>
    </Wrapper>
  );
};

export default Business;
