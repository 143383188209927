import React, { useState, useEffect } from "react";
import {
  Page,
  Card,
  ResourceList,
  ResourceItem,
  TextStyle
} from "@shopify/polaris";
import { db } from "../../firebase";

const Categories = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await db.collection(`categories`).get();

      res.docs.map(async doc => {
        let data = doc.data()

        data.productCount = 0

        await db.collection("products")
          .where("category", "==", data.title.toLowerCase())
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              snapshot.forEach(async (doc) => {
                data.productCount += 1
              });
            }
          });
        return setData(prevValues => [
          ...prevValues,
          { id: doc.id, ...data }
        ]);
      });
      setLoaded(true);
    };

    if (!loaded) {
      fetchData();
    }
  }, [loaded]);

  return (
    <Page
      title="Categories"
      primaryAction={{
        content: "Add Category",
        url: "/products/categories/add",
      }}
    >
      <Card sectioned>
        {loaded && (
          <ResourceList
            resourceName={{ singular: "customer", plural: "customers" }}
            items={data}
            renderItem={(item) => {
              const { title, productCount, id } = item;
              return (
                <ResourceItem
                  id={id}
                  url={`/products/categories/${id}`}
                  accessibilityLabel={`View details for ${title}`}
                >
                  <h3>
                    <TextStyle variation="strong">{title}</TextStyle>
                  </h3>
                  <div>{productCount || 0} products</div>
                </ResourceItem>
              );
            }}
          />
        )}
      </Card>
    </Page>
  );
};

export default Categories;
