import React, { Component } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { uiConfig, auth } from "../firebase";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import devComment from "../utils/devComment";

const Wrapper = styled.div`
    margin: calc(119px + 4rem) auto 4rem;
    width: 90vw;

    p {
        text-align: center;

        a {
            text-decoration: none;
        }
    }
`;

class Login extends Component {
    // state = {
    //   email: '',
    //   password: '',
    // }

    // handleChange = (input) => (event) => {
    //   this.setState({[input]: event.target.value});
    // }

    // handleTest = (event) => {
    //   console.log('test')
    // };

    componentDidMount() {
        devComment(`Login.js : auth => ${JSON.stringify(auth)}`);
    }

    render() {
        return (
            <Wrapper>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                <p>
                    Have you validated your account with Local Guru?{" "}
                    <Link to='/create-account'>Register</Link>
                </p>
                {/* <button onClick={this.handleTest}>Test</button> */}
            </Wrapper>
        );
    }
}

export default connect()(Login);
