import { SET_ANALYTICS, SET_LOADING } from "../actions/AnalyticsActions";
export const CLEAR_ANALYTICS = "clear_analytics";

export default function (
  state = {
    loaded: false,
    sales: {},
    views: {},
    orders: {},
    productCount: {},
    productInfo: {},
    weekly: {
      tmpOrders: { current: 0 },
      tmpViews: { current: 0 },
      tmpSales: { current: 0 },
      tmpProductInfo: {},
      tmpProductCount: {},
    },
    monthly: {
      tmpOrders: { current: 0 },
      tmpViews: { current: 0 },
      tmpSales: { current: 0 },
      tmpProductInfo: {},
      tmpProductCount: {},
    },
  },
  action
) {
  switch (action.type) {
    case SET_ANALYTICS:
      return {
        ...action.payload,
        loaded: true,
      };
    case SET_LOADING:
      return {
        ...state,
        loaded: false,
      };

    case CLEAR_ANALYTICS:
      return {
        loaded: false,
        sales: {},
        views: {},
        orders: {},
        productCount: {},
        productInfo: {},
        weekly: {
          tmpOrders: { current: 0 },
          tmpViews: { current: 0 },
          tmpSales: { current: 0 },
          tmpProductInfo: {},
          tmpProductCount: {},
        },
        monthly: {
          tmpOrders: { current: 0 },
          tmpViews: { current: 0 },
          tmpSales: { current: 0 },
          tmpProductInfo: {},
          tmpProductCount: {},
        },
      };
    default:
      return state;
  }
}
