import React, { Component } from 'react';
import { Page, Card, Heading } from "@shopify/polaris";
import { db } from "../firebase";

class FAQPreview extends Component {
  state = {
    faq: {},
  };

  getFAQ = async () => {
    try {
      const res = await db.collection("pages").doc("faq").get();
      this.setState({
        faq: res.data(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    this.getFAQ();
  }

  editFAQ = () => {
    this.props.history.push("/pages/edit/faq");
  }

  render() {
    return (
      <Page
        title="FAQ"
        primaryAction={{
          content: "Edit",
          onAction: this.editFAQ,
        }}
      >
        <Card sectioned>
            <Heading>Delivery</Heading><br></br>
            {this.state.faq.delivery?.map(item => (
                <>
                <strong>{item.question}</strong>
                <p>{item.answer}</p><br></br>
                </>
            ))}<br></br>
            <Heading>Billing</Heading><br></br>
            {this.state.faq.billing?.map(item => (
                <>
                <strong>{item.question}</strong>
                <p>{item.answer}</p><br></br>
                </>
            ))}<br></br>
            <Heading>Produce</Heading><br></br>
            {this.state.faq.produce?.map(item => (
                <>
                <strong>{item.question}</strong>
                <p>{item.answer}</p><br></br>
                </>
            ))}
        </Card>
      </Page>
    );
  }
}

export default FAQPreview;
