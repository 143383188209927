import React, { useState, useEffect } from "react";
import Axios from "axios";

export default function useBankForm() {
  const [account, setAccount] = useState("");
  const [routing, setRouting] = useState("");

  return {
    account,
    setAccount,
    routing,
    setRouting,
  };
}
