import { db } from "../firebase";

export const FETCH_TRANSACTIONS = "fetch_transactions";

export function fetchTransactionsAdmin() {
  return (dispatch) => {
    return db
      .collection("transactions")
      .orderBy(`created_at`, "desc")
      .get()
      .then((snapshot) => {
        let transactions = [];

        snapshot.docs.map((doc) =>
          transactions.push({ ...doc.data(), id: doc.id })
        );

        return dispatch({
          type: FETCH_TRANSACTIONS,
          payload: transactions,
        });
      });
  };
}

export function fetchTransactions(producer) {
  console.log("PRODUCER TRANS", producer);
  return (dispatch) => {
    return db
      .collection("transactions")
      .where(`producersInOrder.${producer}`, "==", true)
      .get()
      .then((snapshot) => {
        let transactions = [];

        snapshot.forEach((doc) => {
          const order = doc.data();
          console.log("ORDER", order);
          transactions.push(order);
        });

        return dispatch({
          type: FETCH_TRANSACTIONS,
          payload: transactions,
        });
      });
  };
}

export function fetchUserProducerTransaction(user, producer) {
  return db
    .collection("transactions")
    .where(`producers.${producer}`, "==", true)
    .where(`user.uid`, "==", user)
    .get()
    .then((snapshot) => {
      let compiledItems = [];

      snapshot.docs.map((doc) => {
        const { items } = doc.data();
        return Object.values(items).map((item) => {
          if (item.producer === producer) {
            return compiledItems.push(item);
          } else {
            return false;
          }
        });
      });

      return compiledItems;
    });
}

export function fetchSingleTransaction(id) {
  return db
    .collection("transactions")
    .doc(id)
    .get()
    .then((doc) => {
      return doc.data();
    });
}

export function fetchEachTransaction(producer) {
  return (dispatch) => {
    return db
      .collection("transactions")
      .where(`producers.${producer}`, "==", true)
      .get()
      .then((snapshot) => {
        let transactions = [];
        let index = 0;
        snapshot.docs.map((doc) => {
          const { created_at, items } = doc.data();

          return Object.keys(items).map((item) => {
            const itemProducer = items[item].producer;
            index++;

            if (itemProducer === producer) {
              return (transactions[index] = {
                created_at: created_at,
                ...items[item],
              });
            } else {
              return false;
            }
          });
        });

        return transactions;
      });
  };
}

export function changeStatus(array, status, objects) {
  return (dispatch) => {
    if (objects) {
      copyAddresses(objects);
    }
    let batch = db.batch();

    for (const item of array) {
      let itemRef = db.collection("transactions").doc(item);
      batch.update(itemRef, { status: status });
    }

    return batch.commit().then(() => {
      window.location.reload(false);
    });
  };
}

export function copyAddresses(objects) {
  let addresses = [];
  for (let item of objects) {
    // apt: "";
    // city: "St Paul";
    // name: "";
    // state: "Minneapolis";
    // street: "1435 Jessamine Ave W";
    // title: "123";
    // zip: "55108";
    const { apt, street, city, state, zip } = item.shippingAddress;
    let apartment = "";
    if (apt !== "") {
      apartment = " #" + apt;
    }
    addresses.push(`${street}${apartment}, ${city}, ${state}, ${zip}`);
  }

  // Create new element
  let el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = [...new Set(addresses)].join("\n");
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
}
