import { put, takeLatest } from 'redux-saga/effects';
import { db, auth } from '../firebase';

function* loginUser(action) {
  try {
    const { email, password } = action.payload;
    const res = yield auth.signInWithEmailAndPassword(email, password);
    yield console.log(res);
    const response = yield db.collection("producers").doc("AdthtmJARcMcTHXoXWhfA9MhYLk1").get();
    yield console.log(response.data());
    yield put({type: "login_user", payload: response.data()});
  } catch (error) {
    console.log('Error with user login:', error.code, error.message);
    
    }
}

function* logoutUser(action) {
  try {
    yield auth.signOut();
  } catch (error) {
    console.log('Error with user logout:', error);
  }
}

function* loginSaga() {
  yield takeLatest('LOGIN', loginUser);
  yield takeLatest('LOGOUT', logoutUser);
}

export default loginSaga;
