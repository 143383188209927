import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";
import styled from "styled-components";
import { Frame, Layout, Toast, EmptyState, Banner } from "@shopify/polaris";
import Nav from "./components/Nav";
import ViewOrder from "./pages/Orders/ViewOrder";
import NavAdmin from "./components/NavAdmin";
import { Switch, Route, withRouter, Redirect, Link } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Login from "./components/Login";
import Help from "./pages/Help";
import Register from "./pages/Register";
import OpenHouse from "./pages/OpenHouse";
import Orders from "./pages/Orders";
import Disputes from "./pages/Orders/Disputes";
import Analytics from "./pages/Analytics";
import Followers from "./pages/Followers";
import Producers from "./pages/Producers";
import OrdersAdmin from "./pages/Orders/OrdersAdmin";
import EditSingleProduct from "./pages/Products/Edit";
import AddSingleProduct from "./pages/Products/AddSingleProduct";
import EditPageHome from "./pages/pages/Home";
import EditPageLegal from "./pages/pages/Legal";
import Categories from "./pages/Categories";
import EditCategory from "./pages/Categories/Edit";
import AddCategory from "./pages/Categories/Add";
import { toggleToast } from "./actions/UIActions";
import Settings from "./pages/Settings";
import Terms from "./pages/Terms";
import SignedIn from "./pages/SignedIn";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EditPageFAQ from "./pages/pages/FAQ";
import FAQPreview from "./pages/FAQPreview";
import { getProducer, logoutUser } from "./actions/UserActions";
import {
    fetchTransactions,
    fetchTransactionsAdmin,
} from "./actions/TransactionActions";
import Error404 from "./assets/error-404.svg";
import { Alert } from "antd";

const { Section } = Layout;

const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
`;

class App extends Component {
    state = {
        loading: true,
        displayBanner: false,
    };

    componentDidMount() {
        if (!this.props.user.authenticated) {
            this.props.getProducer();
        } else {
            this.setState({
                loading: false,
            });
        }
        if (this.props.user.admin) {
            this.props.fetchTransactionsAdmin();
        } else {
            this.props.fetchTransactions(this.props.user.uid);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.authenticated !== prevProps.user.authenticated) {
            if (!this.props.user.authenticated) {
                this.props.getProducer();
            } else {
                this.setState({
                    loading: false,
                });
            }
            if (this.props.user.admin) {
                this.props.fetchTransactionsAdmin();
            } else {
                this.props.fetchTransactions(this.props.user.uid);
            }
        }
        if (this.props.user.stripe_details !== prevProps.user.stripe_details) {
            if (this.props.user.stripe_details?.payouts_enabled === false) {
                this.setState({ displayBanner: true });
            }
        }
    }

    render() {
        console.log(`
    (
     )
    ()
   |--|
   |  |
 .-|  |-.
:  |  |  :
:  '--'  :
 '-....-'
 `);
        return (
            <Frame>
                {this.props.user.uid ? (
                    <Wrapper>
                        {this.props.user.admin ? (
                            <>
                                <NavAdmin logout={this.props.logoutUser} />
                                <h1>Hello world !</h1>
                            </>
                        ) : (
                            <Nav logout={this.props.logoutUser} />
                        )}

                        <Layout>
                            <Section>
                                {this.state.displayBanner && (
                                    <Banner
                                        title='Account Verification Required'
                                        action={{
                                            content: "Verify Stripe Details",
                                            url: "/account",
                                        }}
                                        status='critical'
                                        onDismiss={() =>
                                            this.setState({
                                                displayBanner: false,
                                            })
                                        }>
                                        <p>
                                            Before being able to sell on Local
                                            Guru, please verify Stripe details.
                                        </p>
                                    </Banner>
                                )}

                                <Switch>
                                    <Redirect
                                        exact
                                        from='/'
                                        to='/home'
                                        component={Home}
                                    />
                                    <Route
                                        exact
                                        path='/home'
                                        component={Home}
                                    />
                                    <Route
                                        path='/product/edit/:id'
                                        component={EditSingleProduct}
                                    />
                                    <Route
                                        path='/order/view/:id'
                                        component={ViewOrder}
                                    />
                                    <Route
                                        path='/disputes'
                                        component={Disputes}
                                    />
                                    <Route
                                        path='/analytics'
                                        component={Analytics}
                                    />
                                    <Route
                                        path='/followers'
                                        component={Followers}
                                    />
                                    <Route
                                        path='/producers'
                                        component={Producers}
                                    />
                                    <Route
                                        exact
                                        path='/products'
                                        component={Products}
                                    />
                                    <Route
                                        path='/products/add'
                                        component={AddSingleProduct}
                                    />
                                    <Route
                                        path='/account'
                                        component={Settings}
                                    />
                                    <Route
                                        path='/create-account'
                                        component={Register}
                                    />
                                    <Route
                                        path='/open-house'
                                        component={OpenHouse}
                                    />
                                    <Route path='/terms' component={Terms} />
                                    <Route
                                        path='/faq-preview'
                                        component={FAQPreview}
                                    />
                                    <Route
                                        path='/signedIn'
                                        component={SignedIn}
                                    />
                                    <Route
                                        exact
                                        path='/products/categories'
                                        component={Categories}
                                    />
                                    <Route
                                        exact
                                        path='/products/categories/add'
                                        component={AddCategory}
                                    />
                                    <Route
                                        path='/products/categories/:id'
                                        component={EditCategory}
                                    />
                                    <Route
                                        path='/privacy-policy'
                                        component={PrivacyPolicy}
                                    />
                                    <Route path='/help' component={Help} />
                                    <Route
                                        path='/pages/edit/home'
                                        component={EditPageHome}
                                    />
                                    <Route
                                        path='/pages/edit/legal'
                                        component={EditPageLegal}
                                    />
                                    <Route
                                        path='/pages/edit/faq'
                                        component={EditPageFAQ}
                                    />
                                    <Route
                                        path='/orders'
                                        component={
                                            this.props.user.admin
                                                ? OrdersAdmin
                                                : Orders
                                        }
                                    />
                                    <Route
                                        render={() => (
                                            <>
                                                {this.state.loading || (
                                                    <EmptyState
                                                        heading='Page Not Found'
                                                        image={Error404}
                                                        fullWidth={true}
                                                        // action={{ content: "Action here?" }}
                                                    >
                                                        Double check the URL and
                                                        try again.
                                                    </EmptyState>
                                                )}
                                            </>
                                        )}
                                    />
                                </Switch>
                            </Section>
                        </Layout>
                    </Wrapper>
                ) : (
                    <Layout>
                        <Switch>
                            <Route exact path='/' component={Login} />
                            <Route
                                path='/create-account'
                                component={Register}
                            />
                            <Route path='/terms' component={Terms} />
                            <Route
                                path='/privacy-policy'
                                component={PrivacyPolicy}
                            />
                            <Route path='/home' component={SignedIn} />
                            {/* <Route
                render={() => (
                  <>
                    <EmptyState
                      heading="Page Not Found"
                      image={Error404}
                      fullWidth={true}
                      // action={{ content: "Action here?" }}
                    >
                      Double check the URL and try again.
                    </EmptyState>
                  </>
                )}
              /> */}
                            <Route
                                render={() => (
                                    <>
                                        {this.state.loading || (
                                            <EmptyState
                                                heading='Page Not Found'
                                                image={Error404}
                                                fullWidth={true}
                                                // action={{ content: "Action here?" }}
                                            >
                                                Double check the URL and try
                                                again.
                                            </EmptyState>
                                        )}
                                    </>
                                )}
                            />
                        </Switch>
                    </Layout>
                )}
                {this.props.ui.showToast ? (
                    <Toast
                        content={this.props.ui.toastText}
                        onDismiss={this.props.toggleToast}
                    />
                ) : null}
            </Frame>
        );
    }
}

export default withRouter(
    connect(
        (state, ownProps) => ({
            ui: state.ui,
            user: state.user,
            transactions: state.transactions,
        }),
        {
            toggleToast,
            fetchTransactions,
            fetchTransactionsAdmin,
            getProducer,
            logoutUser,
        }
    )(App)
);
