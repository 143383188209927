import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import {
  Page,
  Layout,
  Card,
  TextField,
  FormLayout,
  Select,
  Button,
} from "@shopify/polaris";

import styled from "styled-components";

const { Section } = Layout;

// const Line = styled.div`
//     margin-top: 3rem;
//   hr {
//     border: 0;
//     height: 1px;
//     background: #333;
//     background-image: linear-gradient(to right, #ccc, #333, #ccc);
//   }
// `;

const Item = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 5px 0 10px;
  padding: 0 0 0 30px;

  div {
    flex-grow: 4;
  }
  button {
    flex-grow: 0;
  }
`;

const TextWrapper = styled.div`
  button {
    color: #de3618;
  }

  button::hover {
    color: #de3618;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0;
`;

const InnerCard = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const EditPageLegal = (props) => {
  const { history } = props;

  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await db.collection("pages").doc("legal").get();
        setValues(res.data());
        setLoaded(true);
      } catch (e) {
        console.log(e);
      }
    };

    if (!loaded) {
      fetchData();
    }
  }, [loaded]);

  const handleChange = useCallback(
    (value, id) => {
      let idSplit = id.split("-");
      let newValues = values;

      switch (idSplit[0]) {
        case "title":
          newValues.policies[idSplit[1]].title = value;
          break;
        case "text":
          newValues.policies[idSplit[1]]["blocks"][idSplit[2]].text = value;
          break;
        case "item":
          newValues.policies[idSplit[1]]["blocks"][idSplit[2]]["list"][
            idSplit[3]
          ] = value;
          break;
        case "type":
          newValues.policies[idSplit[1]]["blocks"][idSplit[2]].type = value;
          if (
            (value === "list" || value === "bullet") &&
            !newValues.policies[idSplit[1]]["blocks"][idSplit[2]].list
          ) {
            newValues.policies[idSplit[1]]["blocks"][idSplit[2]].list = [];
          }
          break;
        default:
          break;
      }
      setValues((prevValues) => ({
        policies: newValues.policies,
      }));
    },
    [values]
  );

  const addSection = useCallback(
    (type, index = 0, id = 0) => {
      let newValues = values;

      switch (type) {
        case "block":
          newValues.policies[index]["blocks"].push({
            type: "text",
            text: "",
          });
          break;
        case "item":
          newValues.policies[index]["blocks"][id]["list"].push("");
          break;
        case "policy":
          newValues.policies.push({
            title: "",
            blocks: [],
          });
          break;
        default:
          break;
      }

      setValues((prevValues) => ({
        policies: newValues.policies,
      }));
    },
    [values]
  );

  const deleteSection = useCallback(
    (type, index = 0, id = 0, i = 0) => {
      let newValues = values;

      switch (type) {
        case "block":
          newValues.policies[index]["blocks"].splice(id, 1);
          break;
        case "item":
          newValues.policies[index]["blocks"][id]["list"].splice(i, 1);
          break;
        case "policy":
          console.log(newValues.policies);
          newValues.policies.splice(index, 1);
          break;
        default:
          break;
      }

      setValues((prevValues) => ({
        policies: newValues.policies,
      }));
    },
    [values]
  );

  const saveValues = useCallback(async () => {
    try {
      await db.collection("pages").doc("legal").set(values);
    } catch (e) {
      console.log(e);
    }
  }, [values]);

  const viewTerms = () => {
    history.push("/terms");
  };

  if (values.policies) {
    return (
      <Page
        title="Edit Legal"
        separator
        primaryAction={{
          content: "Save",
          onAction: saveValues,
        }}
        secondaryActions={[
          {
            content: "View Legal Page",
            onAction: viewTerms,
          },
        ]}
      >
        <Layout>
          {values.policies.map((policy, index) => (
            <Section key={index}>
              <Card sectioned>
                <FormLayout>
                  <TextWrapper>
                    <TextField
                      label="Policy Title"
                      id={`title-${index}`}
                      value={policy.title}
                      onChange={handleChange}
                      labelAction={{
                        content: "Delete Policy",
                        onAction: () => deleteSection("policy", index),
                        color: "red",
                      }}
                      multiline
                    />
                  </TextWrapper>
                </FormLayout>
                {policy.blocks.length === 0 && <br />}
                {policy.blocks.map((block, id) => (
                  <div key={`${policy.title}-block-${id}`}>
                    <InnerCard>
                      <FormLayout>
                        <Select
                          label="Type: "
                          labelInline
                          id={`type-${index}-${id}`}
                          onChange={handleChange}
                          value={block.type}
                          options={[
                            { label: "Text", value: "text" },
                            { label: "List", value: "list" },
                            { label: "Bullet Points", value: "bullet" },
                          ]}
                        />
                        {block.type === "text" ? (
                          <TextField
                            label="Text"
                            id={`text-${index}-${id}`}
                            value={block.text || ""}
                            onChange={handleChange}
                            multiline
                          />
                        ) : (
                          <>
                            {block.list ? (
                              <>
                                {block.list.map((item, i) => (
                                  <Item key={`${index}-${id}-list-${i}`}>
                                    <TextField
                                      label={`List Item ${i + 1}`}
                                      id={`item-${index}-${id}-${i}`}
                                      value={item}
                                      onChange={handleChange}
                                      multiline
                                    />
                                    <Button
                                      destructive
                                      onClick={() =>
                                        deleteSection("item", index, id, i)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Item>
                                ))}
                              </>
                            ) : (
                              <> </>
                            )}
                            <div style={{ marginLeft: "30px" }}>
                              <Button
                                primary
                                onClick={() => addSection("item", index, id)}
                              >
                                Add Item
                              </Button>
                            </div>
                          </>
                        )}
                      </FormLayout>
                    </InnerCard>
                    <ButtonBlock>
                      {policy.blocks.length === id + 1 ? (
                        <Button
                          primary
                          onClick={() => addSection("block", index)}
                        >
                          New Block
                        </Button>
                      ) : (
                        <div> </div>
                      )}
                      {values.policies.length === 0 ? (
                        <div></div>
                      ) : (
                        <Button
                          destructive
                          onClick={() => deleteSection("block", index, id)}
                        >
                          Delete Block
                        </Button>
                      )}
                    </ButtonBlock>
                  </div>
                ))}
                {policy.blocks.length === 0 && (
                  <Button primary onClick={() => addSection("block", index)}>
                    New Block
                  </Button>
                )}
              </Card>
              {values.policies.length === index + 1 && (
                <ButtonBlock>
                  <Button primary onClick={() => addSection("policy")}>
                    New Policy
                  </Button>
                  <div></div>
                </ButtonBlock>
              )}
            </Section>
          ))}
          {values.policies.length === 0 && (
            <ButtonBlock>
              <Button primary onClick={() => addSection("policy")}>
                New Policy
              </Button>
            </ButtonBlock>
          )}
        </Layout>
      </Page>
    );
  } else {
    return null;
  }
};

export default EditPageLegal;
