import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchTransactions } from "../../actions/TransactionActions";
import {
  Page,
  Card,
  ResourceList,
  TextStyle,
  Pagination,
  Avatar,
  Stack,
  Thumbnail,
  EmptyState,
  Button,
  Popover,
  ActionList,
  Badge,
} from "@shopify/polaris";
import Moment from "react-moment";
import styled from "styled-components";
import emptyOrders from "../../assets/empty-orders.svg";
import { withRouter } from "react-router-dom";
import OrderOverview from "../../components/OrderOverview";
import { exportExcel } from "../../utils/ExportExcel";

const PaginationFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Report = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
`;

class Orders extends Component {
  state = {
    page: 1,
    isLoaded: false,
    modalOpen: false,
    modalType: "",
    selectedItems: [],
    searchValue: "",
    activeSort: false,
    sort: "",
    activeFilter: false,
    filter: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.transactions !== prevProps.transactions) {
      this.setState({ isLoaded: true });
    }
  }

  handleSearchChange = (searchValue) => {
    this.setState({ searchValue });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  toggleActive = (actionList) => (event) => {
    this.setState({ [actionList]: !this.state[actionList] });
  };

  onPrev = () => {
    const { page } = this.state;
    const limit = 50;
    const firstProduct = Object.keys(this.props.transactions)[0];
    const offset = this.props.transactions[firstProduct].created_at;
    this.props.fetchProducts({ endBefore: offset, limit: limit }).then(() => {
      this.setState({ page: page - 1 });
      if (page === 2) {
        this.props.history.push(`/transactions`);
      } else {
        this.props.history.push(`/transactions/page/${this.state.page}`);
      }
    });
  };

  onNext = () => {
    const { page } = this.state;
    const limit = 50;
    const lastProduct = Object.keys(this.props.transactions)[49];
    const offset = this.props.transactions[lastProduct].created_at;
    this.props
      .fetchTransactions({ startAfter: offset, limit: limit })
      .then(() => {
        this.setState({ page: page + 1 });
        this.props.history.push(`/transactions/page/${this.state.page}`);
      });
  };

  handleModalToggle = (type) => {
    this.setState({ modalOpen: !this.state.modalOpen, modalType: type });
  };

  orderTotal = (item) => {
    let total = 0;

    Object.keys(item.items).map((i) => {
      const { price, count, producer } = item.items[i];

      if (producer === this.props.user.uid) {
        return (total = total + price * count);
      } else {
        return false;
      }
    });

    return total;
  };

  listItems = (item) => {
    let list = [];

    Object.keys(item.items).map((i, index) => {
      const { count, title, producer } = item.items[i];

      if (producer === this.props.user.uid) {
        return (list[index] = `${title} x${count}`);
      } else {
        return null;
      }
    });

    return list.join(", ");
  };

  getOverview = (orders, user) => {
    let items = {};

    orders.map((order) => {
      console.log("THE ORDER", order);
      return Object.keys(order.items)
        .filter((item) => {
          const { producer } = order.items[item];
          if (producer === user && order.status === "processing") {
            return true;
          } else {
            return false;
          }
        })
        .map((item) => {
          const { count, price, title, image, unit, uid } = order.items[item];
          if (items[uid]) {
            return (items[uid] = {
              ...items[uid],
              count: count + items[uid].count,
            });
          } else {
            return (items[uid] = {
              count: count,
              price: price,
              title: title,
              image: image,
              unit: unit,
            });
          }
        });
    });

    return Object.values(items);
  };

  getItemTotal = (items, user) => {
    console.log("ITEMS", items);
    let total = 0;
    for (let i of items) {
      console.log("I", i);

      if (i["producer"] === user) {
        total += i.count * i.price;
      }
    }
    console.log("TOTAL", total);
    return total;
  };

  transformTransactions = (transactions) => {
    // create empty object
    let orders = {};
    let arr = [];

    switch (this.state.filter) {
      case "all":
        arr = [...transactions];
        break;
      case "processing":
        for (let transaction of transactions) {
          if (transaction.status === "processing") {
            arr.push(transaction);
          }
        }
        break;
      case "delivered":
        for (let transaction of transactions) {
          if (transaction.status === "delivered") {
            arr.push(transaction);
          }
        }
        break;
      case "on the way":
        for (let transaction of transactions) {
          if (transaction.status === "on the way") {
            arr.push(transaction);
          }
        }
        break;
      case "cancelled":
        for (let transaction of transactions) {
          if (transaction.status === "cancelled") {
            arr.push(transaction);
          }
        }
        break;
      default:
        arr = [...transactions];
        break;
    }

    switch (this.state.sort) {
      case "alphabetically":
        arr.sort(function (a, b) {
          let textA = a.user.displayName.toUpperCase();
          let textB = b.user.displayName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        break;
      case "date":
        arr.sort(function (a, b) {
          let num1 = a.created_at;
          let num2 = b.created_at;
          return num1 < num2 ? 1 : num1 > num2 ? -1 : 0;
        });
        break;
      case "money":
        arr.sort(function (a, b) {
          var textA = a.total;
          var textB = b.total;
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        });
        break;
      default:
        arr.sort(function (a, b) {
          var textA = a.user.displayName.toUpperCase();
          var textB = b.user.displayName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        break;
    }

    // Map through transactions array
    arr.forEach((transaction) => {
      // Get User ID
      console.log("TRX FOR EACH", transaction);
      const { created_at } = transaction;
      // let currentUserOrder = orders[uid];
      // console.log('Test 2: ', currentUserOrder);

      // check if user is in orders object
      // if (currentUserOrder) {
      //   // map through the items in the transaction
      //   Object.keys(transaction.items).map(item => {
      //     let currentItem = currentUserOrder.items[item];
      //     console.log('Test 4: ', currentItem);
      //     // if the items already exists, add to it
      //     if (currentItem?.items) {
      //       const newCount = currentItem.count + transaction.items[item].count;

      //       return (currentUserOrder.items = {
      //         ...currentUserOrder.items,
      //         [item.count]: newCount
      //       });
      //     }
      //     else {
      //       orders[uid].total = orders[uid].total + transaction.total;
      //       return (orders[uid].items[item] = transaction.items[item]);
      //     }
      //   });
      //   // If they don't add the first transaction
      // }
      // else {
      //   orders = { ...orders, [uid]: transaction };
      // }

      orders = { ...orders, [created_at]: transaction };
    });
    return Object.values(orders);
  };

  renderItem = (item) => {
    console.log("RENDER ITEM", item);
    const { id, title, created_at, user, status } = item;

    console.log("TRUE");
    return (
      <ResourceList.Item
        id={id}
        media={
          <Avatar
            customer
            source={user.photoURL ? user.photoURL : null}
            name={user.displayName}
          />
        }
        url={`/order/view/${user.uid}`}
        accessibilityLabel={`View details for ${title}`}
      >
        <h3>
          <TextStyle variation="strong">{user.displayName}</TextStyle>
        </h3>
        <p>
          <TextStyle variation="subdued">
            <Moment format="MMMM Do, YYYY" unix>
              {created_at / 1000}
            </Moment>
          </TextStyle>
        </p>
        <br />
        <p>
          <strong>Status: </strong>
          {status === "delivered" ? (
            <Badge status="success">Delivered</Badge>
          ) : status === "cancelled" ? (
            <Badge status="critical">Cancelled</Badge>
          ) : (
            <Badge status="info">Processing</Badge>
          )}
        </p>
        <p>
          <strong>Total:</strong> $
          {this.getItemTotal(item.items, this.props.user.uid).toFixed(2)}
        </p>
        <p>
          <strong>Items:</strong>{" "}
          <TextStyle variation="subdued">{this.listItems(item)}</TextStyle>
        </p>
        <p>
          <strong>Order: </strong>
          <TextStyle variation="subdued">
            {item.orderNumber?.toUpperCase()}
          </TextStyle>
        </p>
        <br />
        <Card title="Items" sectioned>
          <ResourceList
            items={Object.values(item.items).filter(
              (el) => el.producer === this.props.user.uid
            )}
            renderItem={(item) => {
              const { count, price, title, image, unit } = item;

              const media = <Thumbnail source={image} alt={title} />;

              return (
                <ResourceList.Item media={media}>
                  <Stack distribution="fillEvenly" spacing="extraLoose">
                    <h3>
                      <TextStyle variation="strong">{title}</TextStyle>
                    </h3>
                    <p>
                      {count} x {unit}
                    </p>
                    <p>${(count * price).toFixed(2)}</p>
                  </Stack>
                </ResourceList.Item>
              );
            }}
          />
        </Card>
      </ResourceList.Item>
    );
  };

  render() {
    const resourceName = {
      singular: "order",
      plural: "orders",
    };

    const sortActivator = (
      <Button onClick={this.toggleActive("activeSort")} disclosure>
        Sort
      </Button>
    );

    const filterActivator = (
      <Button onClick={this.toggleActive("activeFilter")} disclosure>
        Filter
      </Button>
    );

    return (
      <Page
        title={
          Object.keys(this.props.transactions).length > 0 ? "Orders" : null
        }
      >
        <Report>
          <Button onClick={() => exportExcel(this.props.transactions)}>
            Download Report
          </Button>
        </Report>
        {this.state.isLoaded &&
        Object.keys(this.props.transactions).length > 0 ? (
          <>
            <OrderOverview
              orders={this.props.transactions}
              user={this.props.user.uid}
            />
            <br />
            <Card title="Orders Panel" sectioned>
              <Wrapper>
                <Popover
                  active={this.state.activeFilter}
                  activator={filterActivator}
                  onClose={this.toggleActive("activeFilter")}
                >
                  <ActionList
                    items={[
                      {
                        content: "All",
                        onAction: () =>
                          this.setState({ filter: "all", activeFilter: false }),
                      },
                      {
                        content: "Processing",
                        onAction: () =>
                          this.setState({
                            filter: "processing",
                            activeFilter: false,
                          }),
                      },
                      {
                        content: "Delivered",
                        onAction: () =>
                          this.setState({
                            filter: "delivered",
                            activeFilter: false,
                          }),
                      },
                      {
                        content: "Cancelled",
                        onAction: () =>
                          this.setState({
                            filter: "cancelled",
                            activeFilter: false,
                          }),
                      },
                    ]}
                  />
                </Popover>
                <div style={{ margin: "0 0 0 6px" }}>
                  <Popover
                    active={this.state.activeSort}
                    activator={sortActivator}
                    onClose={this.toggleActive("activeSort")}
                  >
                    <ActionList
                      items={[
                        {
                          content: "Alphabetically",
                          onAction: () =>
                            this.setState({
                              sort: "alphabetically",
                              activeSort: false,
                            }),
                        },
                        {
                          content: "By Date",
                          onAction: () =>
                            this.setState({ sort: "date", activeSort: false }),
                        },
                        {
                          content: "By Money",
                          onAction: () =>
                            this.setState({ sort: "money", activeSort: false }),
                        },
                      ]}
                    />
                  </Popover>
                </div>
              </Wrapper>
              <ResourceList
                resourceName={resourceName}
                items={this.transformTransactions(this.props.transactions)}
                renderItem={this.renderItem}
                selectedItems={this.state.selectedItems}
                onSelectionChange={this.handleSelectionChange}
              />
              {Object.keys(this.props.transactions).length > 49 && (
                <PaginationFooter>
                  <Pagination
                    hasPrevious={this.state.page > 1}
                    previousKeys={[74]}
                    previousTooltip="j"
                    onPrevious={this.onPrev}
                    hasNext
                    nextKeys={[75]}
                    nextTooltip="k"
                    onNext={this.onNext}
                  />
                </PaginationFooter>
              )}
            </Card>
          </>
        ) : (
          <EmptyState
            image={emptyOrders}
            heading="Manage Orders"
            action={{ content: "Edit products", url: "/products" }}
            secondaryAction={{ content: "Learn more", url: "/help" }}
          >
            Looks like you don't have any orders yet. Check back soon!
          </EmptyState>
        )}
      </Page>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => ({
      transactions: state.transactions,
      user: state.user,
    }),
    { fetchTransactions }
  )(Orders)
);
