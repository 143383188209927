import React, { Component } from "react";
import styled from "styled-components";
import { CardElement } from "react-stripe-elements";
import { db } from "../firebase";
import {
    Tabs,
    Page,
    TextField,
    Card,
    Form,
    FormLayout,
    Scrollable,
    Checkbox,
    InlineError,
} from "@shopify/polaris";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import Survey from "../components/Survey";
import { firebase } from "@firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../firebase";
import { createAccount, verifyProducer } from "../actions/UserActions";
import Axios from "axios";
import BankAccountForm from "../components/BankAccountForm";
import devComment from "../utils/devComment";

const Wrapper = styled.div`
    margin-top: 4rem;

    .Polaris-Page {
    }
`;

const TextBlock = styled.div`
    margin-top: 2.5rem;
`;

const Policy = styled.div`
    margin: 2rem 1rem;
`;

const InnerTextBlock = styled.div`
    margin: 0.75rem 0;
`;

class Register extends Component {
    state = {
        currentTab: 0,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        name: "",
        street: "",
        zip: "",
        city: "",
        token: "",
        agreed: false,
        disabled: true,
        surveyOne: "one",
        surveyTwo: "one",
        account: "",
        routing: "",
        organicPractices: false,
        error: false,
        errorMessage: "",
        terms: {},
    };

    getTerms = async () => {
        try {
            const res = await db.collection("pages").doc("legal").get();
            this.setState({
                terms: res.data(),
            });
        } catch (e) {
            console.log(e);
        }
    };

    componentDidMount() {
        /* devComment(
            `Register.js : Stripe : ${JSON.stringify(this.props.stripe)}`
        ); */
        this.getTerms();
    }

    handleChange = (value, id) => {
        if (id === "firstName" || id === "lastName") {
            this.setState({ [id]: value }, () => this.transformName());
        } else {
            this.setState({ [id]: value });
        }
    };
    handleChoice = (value, name) => {
        this.setState({ [name]: value });
    };

    handleAgreement = (value) => {
        this.setState({ agreed: value });
    };

    handleOrganicPractices = () => {
        this.setState({ organicPractices: !this.state.organicPractices });
    };

    handleTabChange = (selectedTabIndex) => {
        const { currentTab } = this.state;
        console.log("fired", currentTab);

        if (currentTab === 0) {
            if (this.state.password === this.state.passwordConfirm) {
                this.setState({ currentTab: selectedTabIndex });
            } else {
                this.setState({
                    error: true,
                    errorMessage: "Passwords do not match",
                });
            }
        } else {
            this.setState({ currentTab: selectedTabIndex });
        }
    };

    handleCreateToken = (selectedTabIndex) => {
        const { stripe } = this.props;
        if (this.state.account === "") {
            return stripe
                .createToken({
                    type: "card",
                    name: this.state.name,
                    currency: "usd",
                })
                .then((token) => {
                    this.setState({
                        token: token,
                        currentTab: selectedTabIndex,
                    });
                });
        } else {
            return stripe
                .createToken("bank_account", {
                    country: "US",
                    currency: "usd",
                    routing_number: this.state.routing,
                    account_number: this.state.account,
                    account_holder_name: `${this.state.firstName} ${this.state.lastName}`,
                    account_holder_type: "individual",
                })
                .then((token) => {
                    console.log("TOKEN", token);
                    this.setState({
                        ...this.state,
                        token: token,
                        currentTab: selectedTabIndex,
                    });
                })
                .catch((err) => console.log("TOKEN ERROR", err));
        }
    };

    handleStripeConnect = async (accountInfo) => {
        const { stripe } = this.props;
        Axios.post();
    };

    uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: "popup",
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
        tosUrl: "https://local-guru-admin.netlify/terms",
        privacyPolicyUrl: "https://local-guru-admin.netlify/privacy-policy",
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (res) => {
                const { email, displayName } = res.user;
                const firstName = displayName.split(" ")[0];
                const lastName = displayName.split(" ")[1];

                this.setState({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    currentTab: this.state.currentTab + 1,
                });
            },
        },
    };

    setAccount = (account) => {
        this.setState({ ...this.state, account: account });
    };

    setRouting = (routing) => {
        this.setState({ ...this.state, routing: routing });
    };

    transformName = () => {
        this.setState({
            name: `${this.state.firstName} ${this.state.lastName}`,
        });
    };

    handleSubmit = async () => {
        const verify = await verifyProducer(this.state.email);
        if (verify) {
            const profile = {
                displayName: `${this.state.firstName} ${this.state.lastName}`,
                address: {
                    street: this.state.street,
                    zip: this.state.zip,
                    city: this.state.city,
                },
                email: this.state.email,
                token: this.state.token.token,
                organicPractices: this.state.organicPractices,
                followers: [],
            };

            const account = {
                email: this.state.email,
                password: this.state.password,
            };
            console.log("PROFILE", profile, "ACCOUNT", account);

            await createAccount(account, profile, "producers");
            this.props.dispatch({ type: "login_user", payload: profile });
            this.props.history.push("/home");
        } else {
            alert(
                "Error, please contact Local Guru first to validate your account"
            );
            this.props.history.push("/");
        }
    };

    render() {
        const tabContent = [
            {
                id: "account",
                content: (
                    <Card
                        title='Account'
                        sectioned
                        primaryFooterAction={{
                            content: "Next",
                            onAction: () =>
                                this.handleTabChange(this.state.currentTab + 1),
                        }}>
                        {this.state.error && (
                            <InlineError
                                message={this.state.errorMessage}
                                fieldID='password'
                            />
                        )}
                        <Card.Section title='Signup with Email'>
                            <FormLayout>
                                <TextField
                                    id='firstName'
                                    type='text'
                                    value={this.state.firstName}
                                    placeholder='First name'
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    id='lastName'
                                    type='text'
                                    value={this.state.lastName}
                                    placeholder='Last name'
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    id='email'
                                    type='email'
                                    value={this.state.email}
                                    placeholder='Email'
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    id='password'
                                    type='password'
                                    value={this.state.password}
                                    placeholder='Password'
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    id='passwordConfirm'
                                    type='password'
                                    value={this.state.passwordConfirm}
                                    placeholder='Confirm password'
                                    onChange={this.handleChange}
                                />
                            </FormLayout>
                        </Card.Section>
                        <Card.Section title='or Signup with Google / Facebook'>
                            <FormLayout>
                                <StyledFirebaseAuth
                                    uiConfig={this.uiConfig}
                                    firebaseAuth={auth}
                                />
                            </FormLayout>
                        </Card.Section>
                    </Card>
                ),
            },
            {
                id: "survey",
                content: (
                    <Survey
                        handleSubmit={() => this.handleTabChange(2)}
                        back={() =>
                            this.handleTabChange(this.state.currentTab - 1)
                        }
                        handleOrganicPractices={this.handleOrganicPractices}
                        organicPractices={this.state.organicPractices}
                    />
                ),
            },

            {
                id: "payment",
                content: (
                    <>
                        <Card
                            title='Payment'
                            sectioned
                            primaryFooterAction={{
                                content: "Next",
                                onAction: () =>
                                    this.handleCreateToken(
                                        this.state.currentTab + 1
                                    ),
                            }}
                            secondaryFooterActions={[
                                {
                                    content: "Back",
                                    onAction: () =>
                                        this.handleTabChange(
                                            this.state.currentTab - 1
                                        ),
                                },
                            ]}>
                            <Card.Section title='Address'>
                                <FormLayout>
                                    <TextField
                                        id='name'
                                        type='text'
                                        value={this.state.name}
                                        placeholder='Name'
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        id='street'
                                        type='text'
                                        value={this.state.street}
                                        placeholder='Street Address'
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        id='city'
                                        type='text'
                                        value={this.state.city}
                                        placeholder='City'
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        id='zip'
                                        type='text'
                                        value={this.state.zip}
                                        placeholder='ZIP'
                                        onChange={this.handleChange}
                                    />
                                </FormLayout>
                            </Card.Section>
                            <Card.Section title='Card'>
                                <BankAccountForm
                                    account={this.state.account}
                                    setAccount={this.setAccount}
                                    routing={this.state.routing}
                                    setRouting={this.setRouting}
                                />
                            </Card.Section>
                        </Card>
                    </>
                ),
            },
            {
                id: "terms",
                content: (
                    <Card
                        title='Terms of Service'
                        sectioned
                        primaryFooterAction={{
                            content: "Create Account",
                            onAction: () => this.handleSubmit(),
                        }}
                        secondaryFooterAction={{
                            content: "Back",
                            onAction: () =>
                                this.handleTabChange(this.state.currentTab - 1),
                        }}>
                        <FormLayout>
                            <Scrollable shadow style={{ height: "400px" }}>
                                {this.state.terms.policies &&
                                    this.state.terms.policies.map(
                                        (policy, index) => (
                                            <Policy key={index}>
                                                <h4>{policy.title}</h4>
                                                {policy.blocks.map(
                                                    (block, id) => (
                                                        <>
                                                            {block.type ===
                                                                "text" && (
                                                                <TextBlock>
                                                                    {block.text
                                                                        .split(
                                                                            "\n"
                                                                        )
                                                                        .map(
                                                                            (
                                                                                text
                                                                            ) => (
                                                                                <InnerTextBlock>
                                                                                    {
                                                                                        text
                                                                                    }
                                                                                </InnerTextBlock>
                                                                            )
                                                                        )}
                                                                </TextBlock>
                                                            )}
                                                            {block.type ===
                                                                "list" && (
                                                                <ol>
                                                                    {block.list.map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <li>
                                                                                {
                                                                                    item
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ol>
                                                            )}
                                                            {block.type ===
                                                                "bullet" && (
                                                                <ul>
                                                                    {block.list.map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <li>
                                                                                {
                                                                                    item
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </Policy>
                                        )
                                    )}
                            </Scrollable>
                            <Checkbox
                                checked={this.state.agreed}
                                label='I Agree'
                                onChange={this.handleAgreement}
                            />
                        </FormLayout>
                    </Card>
                ),
            },
        ];

        const tabs = [
            {
                id: "account",
                content: "Account",
            },
            {
                id: "survey",
                content: "Survey",
            },

            {
                id: "payment",
                content: "Payment",
            },
            {
                id: "terms",
                content: "Terms",
            },
        ];

        return (
            <>
                <Wrapper>
                    <Page
                        title='Create Account'
                        style={{ width: "auto!important" }}>
                        <Form>
                            <Tabs
                                tabs={tabs}
                                fitted
                                selected={this.state.currentTab}
                                onSelect={this.handleTabChange}>
                                <br />
                                {tabContent[this.state.currentTab].content}
                            </Tabs>
                        </Form>
                    </Page>
                </Wrapper>
            </>
        );
    }
}

const mapStateToProps = (reduxStore) => ({
    ui: reduxStore.ui,
    user: reduxStore.user,
});

export default injectStripe(withRouter(connect(mapStateToProps)(Register)));
