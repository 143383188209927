import { takeLatest } from 'redux-saga/effects';
import { db, auth } from '../firebase';

function* createProducerAccount (action){
    try{
        const { email, password } = action.payload.account;
        const response = yield auth.createUserWithEmailAndPassword(email, password);
        console.log(response.user.uid);
        yield db.collection("producers").doc(response.user.uid).set(action.payload.producer);
    }
    catch(error){
        console.log('Error in createProducer', error);
    }
}

function* userSaga(){
    yield takeLatest('CREATE_PRODUCER', createProducerAccount);
}

export default userSaga;